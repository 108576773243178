import type { $TSFixMe } from '@readme/iso';
import type { Column } from '@tanstack/react-table';

import React, { useState, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';

import classes from '../style.module.scss';

import DragColumn from './DragColumn';

interface Props {
  allColumns: Column<$TSFixMe>[];
  setColumnOrder: (columns: string[]) => void;
  /** Theme override prop for <Menu> */
  theme?: 'dark';
}

const ColumnSelector = ({ allColumns, setColumnOrder, theme }: Props) => {
  const bem = useClassy(classes, 'ReactTable');

  // Filter out columns with no header
  const orderableColumns = allColumns.filter(c => c.columnDef.header !== '');

  const [columns, setColumns] = useState(orderableColumns);
  const { isClient } = useEnvInfo();

  const findColumn = useCallback(
    (id: string) => {
      const column = columns.find(c => c.id === id);

      if (!column) return {};

      return {
        column,
        index: columns.indexOf(column),
      };
    },
    [columns],
  );

  const moveColumn = useCallback(
    (id: string, atIndex: number) => {
      const { column, index } = findColumn(id);

      if (!column || index === undefined) return;

      const newColumns = [...columns];

      // Remove dragged item
      newColumns.splice(index, 1);

      // Add new item
      newColumns.splice(atIndex, 0, column);

      setColumns(newColumns);
    },
    [findColumn, columns, setColumns],
  );

  const onDrop = useCallback(() => {
    const newColumnOrder = columns.map(c => c.id);
    setColumnOrder(newColumnOrder);
  }, [columns, setColumnOrder]);

  return (
    <Dropdown appendTo={() => document.body} className={bem('-columnSelector')} sticky>
      <Button className={bem('-columnSelector-btn')} ghost kind="minimum" size="xs">
        <Icon name="more-vertical" />
      </Button>
      <Menu theme={theme} {...(theme === 'dark' && { 'data-color-mode': 'dark' })}>
        <DndProvider backend={HTML5Backend} context={isClient ? window : undefined}>
          <MenuHeader>Columns</MenuHeader>

          {columns.map(column => {
            return (
              <DragColumn
                key={column.id}
                column={column}
                columnId={column.id}
                findColumn={findColumn}
                moveColumn={moveColumn}
                onDrop={onDrop}
              />
            );
          })}
        </DndProvider>
      </Menu>
    </Dropdown>
  );
};

export default ColumnSelector;
