import React from 'react';

import Graphic, { doesGraphicExist } from '@ui/Graphic';
import Icon from '@ui/Icon';

const UserAgentPlatformGraphic = ({ platform }: { platform?: string | false }) => {
  const hasGraphic = doesGraphicExist(platform ? platform.toLowerCase() : false);

  if (platform && platform.length && hasGraphic) {
    return <Graphic name={platform.toLowerCase()} />;
  }

  return <Icon color="gray60" name="help-circle" size="sm" />;
};

export default React.memo(UserAgentPlatformGraphic);
