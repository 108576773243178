import React, { useContext } from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import { AccordionContext } from './context';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  itemId?: string;
}

function AccordionToggle({ children, className, itemId }: Props) {
  const bem = useClassy(classes, 'AccordionToggle');
  const { activePanelIds, handleToggle, headingLevel, openIcon, closeIcon } = useContext(AccordionContext);
  const isActive = itemId ? activePanelIds.includes(itemId) : false;
  const Tag = `h${headingLevel}` as React.ElementType;
  const useCustomIcons = openIcon && closeIcon;
  return (
    <Tag className={`${bem('&', isActive && '_active', className)} ${isActive ? 'active' : ''}`}>
      <button
        aria-controls={itemId}
        aria-expanded={isActive}
        id={`${itemId}-toggle`}
        onClick={() => itemId && handleToggle(itemId)}
        type="button"
      >
        {children}
        <div className={bem('-icon-slot')}>
          {useCustomIcons ? (
            isActive ? (
              closeIcon
            ) : (
              openIcon
            )
          ) : (
            <Icon aria-label="Chevron" className={bem('-icon')} name="chevron-right" />
          )}
        </div>
      </button>
    </Tag>
  );
}

export default AccordionToggle;
