import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';
import prettyNumber from '@core/utils/prettyNumber';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Select from '@ui/Select';

import classes from './style.module.scss';

const ListPagination = ({ className, children, loading, page, pages, onPrevious, onNext, onPage }) => {
  const disablePrev = loading || page <= 1;
  const disableNext = loading || page === pages;
  const disableSwitcher = page === 0 && pages === 0;

  const dropdownLabelClasses = classy(
    classes['Pagination-dropdown'],
    disableSwitcher && classes['Pagination-dropdown_disabled'],
    'Button',
    'Button_sm',
    'Button_minimum',
    'Button_minimum_text',
  );
  const dropdownChevronClasses = classy(classes['Pagination-dropdown-icon'], 'icon', 'icon-chevron-up-down');

  return (
    <Flex
      align="center"
      aria-label="Pagination Controls"
      className={classy(classes.Pagination, className)}
      justify="between"
    >
      {pages < 500 ? (
        <label aria-label="Page Switcher" className={dropdownLabelClasses}>
          <Flex align="center" gap="xs" justify="between">
            <span className={`${classes['Pagination-dropdown-label']}`}>
              Page {page} of {pages || 1}
            </span>
            <span className={dropdownChevronClasses} />
            <Select
              className={`${classes['Pagination-dropdown-Select']}`}
              disabled={disableSwitcher}
              onChange={onPage}
              options={Array.from({ length: pages }, (_, i) => i + 1).map(num => {
                return { label: num.toString(), value: num };
              })}
              size="sm"
              value={page}
            />
          </Flex>
        </label>
      ) : (
        <span className={`${classes['Pagination-dropdown-label']}`}>
          Page {prettyNumber(page)} of {prettyNumber(pages)}
        </span>
      )}

      {/* Custom buttons */}
      {!!children && <div className={classes['Pagination-children']}>{children}</div>}

      <Flex align="center" gap="xs">
        <Button
          aria-label="Back"
          disabled={disablePrev}
          ghost
          kind="secondary"
          onClick={onPrevious}
          shift="start"
          size="sm"
        >
          <i className="icon icon-chevron-leftward" />
          <span>Back</span>
        </Button>
        <Button aria-label="Next" disabled={disableNext} ghost kind="secondary" onClick={onNext} size="sm">
          <span>Next</span>
          <i className="icon icon-chevron-rightward" />
        </Button>
      </Flex>
    </Flex>
  );
};

ListPagination.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
  onPage: PropTypes.func,
  onPrevious: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
};

export default ListPagination;
