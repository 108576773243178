import React, { useContext, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import AccordionContent from './Content';
import { AccordionContext } from './context';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  initiallyOpen?: boolean;
}

function AccordionPanel({ children, className, initiallyOpen }: Props) {
  const { handleToggle, activePanelIds } = useContext(AccordionContext);
  const bem = useClassy(classes, 'AccordionPanel');
  const uid = useUniqueId('AccordionPanel');
  const panelId = uid();

  const isActive = activePanelIds.includes(panelId);

  // We have to wait for a subsequent render (after the uid placeholder is populated with a hash)
  // before toggling an initially open panel
  useEffect(() => {
    if (initiallyOpen && !panelId.includes('static-id-placeholder')) {
      handleToggle(panelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelId]);

  return (
    <div className={bem('&', isActive && '_active', className)}>
      {React.Children.map(children, (child: React.ReactNode, i) => {
        if (i === 0 && React.isValidElement(child)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const toggle = React.cloneElement(child as React.ReactElement<any>, {
            itemId: panelId,
          });
          return toggle;
        }

        if (i === 1) {
          return <AccordionContent itemId={panelId}>{child}</AccordionContent>;
        }

        return child;
      })}
    </div>
  );
}

export default AccordionPanel;
