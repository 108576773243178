/**
 * Get the value of a css variable
 * @example getCSSVar('--blue') -> "#018ef5"
 */

function getCSSVar(varName: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

export default getCSSVar;
