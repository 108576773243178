import type { HTTPMethod } from '@readme/iso';

import { format } from 'date-fns';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { getElapsed } from '@core/utils/metrics';

import APIMethod from '@ui/API/Method';
import Avatar from '@ui/Avatar';
import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import UserAgentPlatformGraphic from '@ui/Metrics/UserAgentPlatformGraphic';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import SpeechBubble from '@ui/SpeechBubble';
import Timestamp from '@ui/Timestamp';
import Tooltip from '@ui/Tooltip';

import classes from '../style.module.scss';

const TOOLTIP_DELAY: [number | null, number | null] = [500, null];
const TOOLTIP_OFFSET: [number, number] = [0, 5];

export const EmptyCell = () => {
  const bem = useClassy(classes, 'ReactTable');
  return <span className={bem('-td-empty')}>&ndash;</span>;
};

/** Cell with copy-to-clipboard functionality on hover */
export const CopyableCell = ({ children, text }: { children: React.ReactNode; text?: string }) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!text) return <>{children}</>;

  return (
    <Flex align="center" className={bem('-td-copyable', '-td-truncate')} gap="xs" justify="start">
      {children}
      <CopyToClipboard className={bem('-td-copyable-clipboard')} text={text} />
    </Flex>
  );
};

/** Simple Text Cell w/ delayed tooltip on hover */
export const TextCell = ({
  copyable,
  showTooltip = true,
  value,
  tooltipValue,
}: {
  copyable?: boolean;
  showTooltip?: boolean;
  tooltipValue?: string;
  value?: string;
}) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!value) return <EmptyCell />;

  const Tag = copyable ? CopyableCell : React.Fragment;

  return (
    <Tag {...(copyable ? { text: value } : {})}>
      {showTooltip ? (
        <Tooltip
          arrow={false}
          content={tooltipValue || value}
          delay={TOOLTIP_DELAY}
          offset={TOOLTIP_OFFSET}
          placement="bottom-start"
          title
        >
          <span className={bem('-td-truncate')}>{value}</span>
        </Tooltip>
      ) : (
        <span>{value}</span>
      )}
    </Tag>
  );
};

export const MethodCell = ({ value }: { value?: HTTPMethod }) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!value) return null;

  return (
    <Flex align="center" gap="sm" justify="start">
      <div className={bem('-td-method')}>
        <APIMethod fixedWidth type={value} />
      </div>
    </Flex>
  );
};

export const StatusCell = ({ value }: { value?: number }) => {
  if (!value) return null;

  return (
    <Flex align="center" gap="sm" justify="start">
      <HTTPStatus status={value} />
    </Flex>
  );
};

export const TimeCell = ({ value }: { value?: string }) => {
  return <Timestamp formatter={date => format(date, 'M/d/yy h:mm a')} value={new Date(value as string)} />;
};

export const VoteCell = ({ value }: { value: number }) => {
  return (
    <Flex align="center" justify="center">
      <i
        className={value === 0 ? 'icon-thumbs-down1' : 'icon-thumbs-up-2'}
        style={{ color: value === 0 ? 'var(--red)' : 'var(--green)' }}
      />
    </Flex>
  );
};

export const CommentCell = ({ value }: { value: string }) => {
  if (!value) return null;

  return <SpeechBubble content={value} isExpandable />;
};

export const CompanyCell = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  value: string | { logo: string; name: string };
}) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!value) return <EmptyCell />;

  if (typeof value === 'string') {
    return (
      <>
        <CopyableCell text={value}>
          <Tooltip
            arrow={false}
            content={value}
            delay={TOOLTIP_DELAY}
            offset={TOOLTIP_OFFSET}
            placement="bottom-start"
            title
          >
            <span className={bem('-td-truncate')}>{value}</span>
          </Tooltip>
        </CopyableCell>

        {children}
      </>
    );
  }

  return (
    <Flex align="center" gap="sm" justify="start">
      {!!value?.logo && <Avatar className={bem('-td-company-avatar')} imgSrc={value?.logo} size="xs" />}

      {value?.name?.length ? (
        <CopyableCell text={value?.name}>
          <Tooltip
            arrow={false}
            content={value?.name}
            delay={TOOLTIP_DELAY}
            offset={TOOLTIP_OFFSET}
            placement="bottom"
            title
          >
            <span className={bem('-td-truncate')}>{value?.name}</span>
          </Tooltip>
        </CopyableCell>
      ) : (
        <EmptyCell />
      )}

      {children}
    </Flex>
  );
};

export const UserAgentCell = ({ value }: { value: string | { name: string; platform: string } }) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!value) return <EmptyCell />;

  if (typeof value === 'string') {
    return (
      <Tooltip
        arrow={false}
        content={value}
        delay={TOOLTIP_DELAY}
        offset={TOOLTIP_OFFSET}
        placement="bottom-start"
        title
      >
        <span>{value}</span>
      </Tooltip>
    );
  }

  return (
    <Flex align="center" gap="sm" justify="start">
      <UserAgentPlatformGraphic platform={value.platform} />

      {/* User agents aren't always guaranteed. */}
      {value.name?.length ? (
        <Tooltip arrow={false} content={value.name} delay={[500, null]} offset={[0, 5]} placement="bottom" title>
          <span className={bem('-td-truncate')}>{value.name}</span>
        </Tooltip>
      ) : (
        <EmptyCell />
      )}
    </Flex>
  );
};

export const APIKeyCell = ({ value }: { value: string }) => {
  if (!value) return <EmptyCell />;

  return (
    <Tooltip arrow={false} content="API Keys are hashed prior to storage">
      <ObfuscatedAPIKey allowCopy={false} allowExpansion={false} apiKey={value} conceal="before" displayLength={4} />
    </Tooltip>
  );
};

export const ElapsedTimeCell = ({ value }: { value: string }) => {
  const bem = useClassy(classes, 'ReactTable');

  if (!value) return <EmptyCell />;

  return (
    <span className={bem('-td-truncate')}>
      <Timestamp formatter={date => getElapsed(date.toISOString(), { includeAgo: true })} value={new Date(value)} />
    </span>
  );
};
