import React from 'react';

import useClassy from '@core/hooks/useClassy';

import { AccordionContext, useAccordionContext } from './context';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  /**
   * Override the icon for the 'close' action (appears in a panel's expanded state)
   */
  closeIcon?: React.ReactNode;
  /**
   * Pass `true` if you want to prevent all panels from being closed (ie. one panel is always open at any given time).
   * Setting to `false` will allow any number of panels to be open or closed.
   */
  collapsible?: boolean;
  /**
   * Each accordion toggle is wrapped in a `role="heading"` element that should have a heading level that
   * fits correctly within the outline of the page. The default level is `1`, but you can use the
   * `headingLevel` prop on the `Accordion` component to change that.
   */
  headingLevel?: number | string;
  /**
   * Override the icon for the 'open' action (appears in a panel's collapsed state)
   */
  openIcon?: React.ReactNode;
}

function Accordion({ children, className, collapsible = true, headingLevel = 1, openIcon, closeIcon }: Props) {
  const bem = useClassy(classes, 'Accordion');
  const AccordionContextValue = useAccordionContext(collapsible, headingLevel, openIcon, closeIcon);

  return (
    <AccordionContext.Provider value={AccordionContextValue}>
      <section className={bem('&', className)} data-testid="accordion">
        {children}
      </section>
    </AccordionContext.Provider>
  );
}

export { default as AccordionPanel } from './Panel';
export { default as AccordionToggle } from './Toggle';

export default Accordion;
