import type { Group } from './types';
import type { CSSProperties } from 'react';

import copy from 'clipboard-copy';
import React, { useCallback, useMemo } from 'react';

import abbreviateNumber from '@core/utils/abbreviatedNumber';
import classy from '@core/utils/classy';
import obfuscateKey from '@core/utils/obfuscateKey';

import Badge from '@ui/Badge';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';
import { groupByFilterDisplayName } from '@ui/Metrics/Graph/utils';
import Toggle from '@ui/Toggle';

import { EMPTY_VALUE } from './constants';
import classes from './style.module.scss';

interface Props {
  baseUrl: string;
  groupFilterName: string;
  groupFilters: string[];
  groupLabels: Group[];
  isStacked: boolean;
  subdomain: string;
  title: string;
  updateGroupFilters: (groupFilters: string[], isAll: boolean) => void;
  version: string;
}

function GroupFilters({
  baseUrl,
  groupFilterName,
  groupFilters,
  groupLabels,
  isStacked,
  subdomain,
  title,
  updateGroupFilters,
  version,
}: Props) {
  const isAllSelected = useMemo(
    () => groupFilters.length > 0 && groupFilters.length === groupLabels.length,
    [groupFilters, groupLabels],
  );

  const toggleGroupFilter = useCallback(
    (group: string) => {
      const index = groupFilters.indexOf(group);
      const nextGroupFilters = [...groupFilters];
      if (index === -1) {
        nextGroupFilters.push(group);
      } else {
        nextGroupFilters.splice(index, 1);
      }

      const isAll = nextGroupFilters.length > 0 && nextGroupFilters.length === groupLabels.length;
      updateGroupFilters(nextGroupFilters, isAll);
    },
    [groupFilters, groupLabels, updateGroupFilters],
  );

  const toggleAllGroups = useCallback(
    (isAll: boolean) => {
      const nextGroupFilters = isAll ? [...groupLabels.map(group => group.name)] : [];
      updateGroupFilters(nextGroupFilters, isAll);
    },
    [groupLabels, updateGroupFilters],
  );

  const parseGroupLabel = useCallback(
    (group: Group) => {
      // Obfuscate displayed API Keys, but pass the original value when toggling and copying to clipboard
      if (groupFilterName === 'Group ID') {
        return {
          label: obfuscateKey(group.name, 'before', 4) || EMPTY_VALUE,
          value: group.name || null,
        };
      }

      return {
        label: group.url || group.name || EMPTY_VALUE,
        value: group.url || group.name || null,
      };
    },
    [groupFilterName],
  );

  return (
    <Flex className={classes['MetricsGraph-aside']} gap="5" justify="start" layout="col">
      <div className={classy(classes['MetricsGraph-group'], classes['MetricsGraph-aside-heading'])}>
        <Toggle
          key={'toggle_all'}
          checked={isAllSelected}
          className={classes['MetricsGraph-group-toggle']}
          isIndeterminate={!isAllSelected && groupFilters.length !== groupLabels.length}
          label={groupByFilterDisplayName(groupFilterName)}
          onChange={() => toggleAllGroups(!isAllSelected)}
          style={{ '--toggle-checked-color': 'var(--gray40)' } as CSSProperties}
          type="checkbox"
        />
        <Badge kind="light">{groupLabels.length}</Badge>
      </div>
      {groupLabels.map(group => {
        const { label, value } = parseGroupLabel(group);
        const isNavigablePath = groupFilterName === 'Path' && ['Page Views', 'Page Quality'].includes(title);
        const pathArray = isNavigablePath ? label.split('/').slice(1) : null;
        // Checks if this is a doc or changelog page and it's not just the root path (which isn't editable)
        const isEditablePage = pathArray && ['docs', 'changelog'].includes(pathArray[0]) && pathArray.length > 1;

        return (
          <Dropdown
            key={group.name}
            align="left"
            className={classes['MetricsGraph-aside-tooltip']}
            justify="start"
            trigger="hover"
          >
            <div className={classes['MetricsGraph-group']}>
              <Toggle
                key={group.name}
                checked={groupFilters.includes(group.name)}
                className={classes['MetricsGraph-group-toggle']}
                label={label}
                onChange={() => toggleGroupFilter(group.name)}
                style={{ '--toggle-checked-color': !isStacked ? `rgb(${group.color})` : '' } as CSSProperties}
                type="checkbox"
              />
              <Badge className={classes['MetricsGraph-group-badge']} kind="light">
                {abbreviateNumber(group.total)}
              </Badge>
            </div>
            <Menu>
              <MenuHeader>{label}</MenuHeader>
              {(groupFilters.length > 1 || (groupFilters.length === 1 && !groupFilters.includes(group.name))) && (
                <MenuItem onClick={() => updateGroupFilters([group.name], false)}>Filter By Just This</MenuItem>
              )}

              <MenuItem onClick={() => copy(value || EMPTY_VALUE)}>
                {`Copy ${groupByFilterDisplayName(groupFilterName)} to Clipboard`}
              </MenuItem>

              {!!isNavigablePath && (
                <>
                  <MenuDivider />
                  <MenuHeader>For This Page</MenuHeader>
                  {/* RM-2943 Enable the ability for page views to nav to page quality and vice versa once our routing allows us to navigate to another graph with specific group filters enabled */}
                  {!!isEditablePage && (
                    <MenuItem
                      alignIcon="right"
                      color="blue"
                      href={`${baseUrl}${value}`}
                      icon="icon-arrow-right2"
                      TagName="a"
                    >
                      Edit Page
                    </MenuItem>
                  )}
                  <MenuItem
                    alignIcon="right"
                    color="blue"
                    href={`/go/${subdomain}?redirect=/v${version}${value}`}
                    icon="icon-arrow-up-right"
                    TagName="a"
                    target="_blank"
                  >
                    Open Page
                  </MenuItem>
                </>
              )}
            </Menu>
          </Dropdown>
        );
      })}
    </Flex>
  );
}

export default GroupFilters;
