import { camelCase } from 'lodash';

export function graphTypeToUnits(graphType: string, count?: number) {
  const isPlural = count !== 1;
  switch (graphType) {
    case 'API Errors':
      return isPlural ? 'Errors' : 'Error';
    case 'Page Quality':
      return isPlural ? 'Votes' : 'Vote';
    case 'Page Views':
      return isPlural ? 'Views' : 'View';
    case 'New Users':
      return isPlural ? 'Users' : 'User';
    case 'Search':
      return isPlural ? 'Searches' : 'Search';
    case 'API Calls':
    case 'Top Endpoints':
    default:
      return isPlural ? 'Calls' : 'Call';
  }
}

export function groupByFilterDisplayName(groupByFilter: string) {
  switch (groupByFilter) {
    case 'Path':
      return 'Page';
    case 'Group Email':
      return 'Email';
    case 'Group ID':
      return 'API Key';
    default:
      return groupByFilter;
  }
}

export function groupByFilterToColumn(groupFilterName: string) {
  switch (groupFilterName) {
    case 'User Agent':
      return 'useragent';
    case 'URL':
      return 'path';
    default:
      return camelCase(groupFilterName);
  }
}
