import { StatusCodes } from 'http-status-codes';

type StatusCategories = Record<string, StatusCodes[]>;

const statusCategories: StatusCategories = {
  '100s': [StatusCodes.CONTINUE, StatusCodes.SWITCHING_PROTOCOLS, StatusCodes.PROCESSING],
  '200s': [
    StatusCodes.OK,
    StatusCodes.CREATED,
    StatusCodes.ACCEPTED,
    StatusCodes.NON_AUTHORITATIVE_INFORMATION,
    StatusCodes.NO_CONTENT,
    StatusCodes.RESET_CONTENT,
    StatusCodes.PARTIAL_CONTENT,
    StatusCodes.MULTI_STATUS,
  ],
  '300s': [
    StatusCodes.MULTIPLE_CHOICES,
    StatusCodes.MOVED_PERMANENTLY,
    StatusCodes.MOVED_TEMPORARILY,
    StatusCodes.SEE_OTHER,
    StatusCodes.NOT_MODIFIED,
    StatusCodes.USE_PROXY,
    StatusCodes.TEMPORARY_REDIRECT,
    StatusCodes.PERMANENT_REDIRECT,
  ],
  '400s': [
    StatusCodes.BAD_REQUEST,
    StatusCodes.UNAUTHORIZED,
    StatusCodes.PAYMENT_REQUIRED,
    StatusCodes.FORBIDDEN,
    StatusCodes.NOT_FOUND,
    StatusCodes.METHOD_NOT_ALLOWED,
    StatusCodes.NOT_ACCEPTABLE,
    StatusCodes.PROXY_AUTHENTICATION_REQUIRED,
    StatusCodes.REQUEST_TIMEOUT,
    StatusCodes.CONFLICT,
    StatusCodes.GONE,
    StatusCodes.LENGTH_REQUIRED,
    StatusCodes.PRECONDITION_FAILED,
    StatusCodes.REQUEST_TOO_LONG,
    StatusCodes.REQUEST_URI_TOO_LONG,
    StatusCodes.UNSUPPORTED_MEDIA_TYPE,
    StatusCodes.REQUESTED_RANGE_NOT_SATISFIABLE,
    StatusCodes.EXPECTATION_FAILED,
    StatusCodes.IM_A_TEAPOT,
    StatusCodes.INSUFFICIENT_SPACE_ON_RESOURCE,
    StatusCodes.METHOD_FAILURE,
    StatusCodes.UNPROCESSABLE_ENTITY,
    StatusCodes.LOCKED,
    StatusCodes.FAILED_DEPENDENCY,
    StatusCodes.PRECONDITION_REQUIRED,
    StatusCodes.TOO_MANY_REQUESTS,
    StatusCodes.REQUEST_HEADER_FIELDS_TOO_LARGE,
    StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS,
  ],
  '500s': [
    StatusCodes.INTERNAL_SERVER_ERROR,
    StatusCodes.NOT_IMPLEMENTED,
    StatusCodes.BAD_GATEWAY,
    StatusCodes.SERVICE_UNAVAILABLE,
    StatusCodes.GATEWAY_TIMEOUT,
    StatusCodes.HTTP_VERSION_NOT_SUPPORTED,
    StatusCodes.INSUFFICIENT_STORAGE,
    StatusCodes.NETWORK_AUTHENTICATION_REQUIRED,
  ],
};

export default statusCategories;
