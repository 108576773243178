import type { Instance } from 'tippy.js';

/**
 * Hides the tippy when the escape key is pressed. (https://atomiks.github.io/tippyjs/v6/plugins/#hideonesc).
 * To use this, include it in the Tippy plugins[] prop
 * <Tippy plugins=[hideOnEscape]>
 */
export const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn(instance: Instance<{ onClickOutside?: () => void }>) {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        /**
         * We want to treat Esc like a click outside of tooltip so that any controlled open props are used,
         * so we'll try to tap into that callback if it exists. Otherwise, we'll just hide instance.
         */
        if (instance.props.onClickOutside) {
          instance.props.onClickOutside();
          return;
        }

        instance.hide();
      }
    }

    return {
      onShow() {
        document.addEventListener('keydown', onKeyDown);
      },
      onHide() {
        document.removeEventListener('keydown', onKeyDown);
      },
    };
  },
};
