import { isEmpty } from 'lodash';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import type { APIRequestData } from '@core/types/metrics';
import abbreviatedNumber from '@core/utils/abbreviatedNumber';

import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Sparkline from '@ui/Sparkline';
import Spinner from '@ui/Spinner';

import styles from './styles.module.scss';

interface Props {
  hideAPIRequestsGraph?: boolean;
  requestData?: APIRequestData;
  useDarkMode?: boolean;
}

const calculateTotalRequests = (data: number[]) => data.reduce((acc, el) => acc + el, 0);
const calculateErrorRate = ({ failure, success }) => {
  const calc = failure / success;
  // only show % up to 2 decimal places
  return calc >= 100 ? 100 : calc.toFixed(2);
};

const ErrorRate = ({ data }: { data: APIRequestData['data'] }) => {
  const success = calculateTotalRequests(data[0]);
  const failure = calculateTotalRequests(data[1]);
  const errorRate = calculateErrorRate({ failure, success });

  return (
    <Flex align="center" tag="div">
      <Flex gap={0} tag="span">
        <Flex align="center" gap={0} tag="span">
          <HTTPStatus iconOnly status={200} />
          {abbreviatedNumber(success)}
        </Flex>
        <Flex align="center" gap={0} tag="span">
          <HTTPStatus iconOnly status={500} />
          {abbreviatedNumber(failure)}
        </Flex>
      </Flex>
      <span>Error Rate: {errorRate}%</span>
    </Flex>
  );
};

const APIRequestsGraph = ({ hideAPIRequestsGraph = false, requestData, useDarkMode = false }: Props) => {
  const bem = useClassy(styles, 'APIRequestGraph');

  if (hideAPIRequestsGraph) return null;

  if (!requestData || !requestData.data) {
    return (
      <Flex align="center" className={bem('&', '-loading')} justify="center">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (isEmpty(requestData?.data)) {
    return (
      <Flex align="center" className={bem('&', '-empty')} justify="center">
        <Flex align="center" className={bem('-empty-ctn')} justify="center">
          No data found
        </Flex>
      </Flex>
    );
  }

  return (
    <div className={bem('&')}>
      <Sparkline
        className={bem('-graph')}
        data={requestData.data}
        labels={requestData.labels}
        maxWidth="230"
        useDarkMode={useDarkMode}
      />
      <ErrorRate data={requestData.data} />
    </div>
  );
};

export default APIRequestsGraph;
