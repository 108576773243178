import React, { useState, useRef } from 'react';

import useOnClickOutside from '@core/hooks/useOnClickOutside';
import classy from '@core/utils/classy';

import type { Props as AvatarProps } from '@ui/Avatar';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Flex from '@ui/Flex';

import classes from './style.module.scss';
import Tail from './tail';

export enum BubbleType {
  IncomingMessage,
  OutgoingMessage,
}

interface Props {
  avatarProps?: AvatarProps & { name: string };
  className?: string;
  content?: React.ReactNode | string;
  isExpandable?: boolean;
  isSeen?: boolean;
  onSeen?: (isSeen: boolean) => void;
  subtext?: React.ReactNode | string;
  type?: BubbleType;
}

function SpeechBubble(props: Props) {
  const {
    className,
    isExpandable = false,
    avatarProps,
    content,
    onSeen,
    type = BubbleType.IncomingMessage,
    subtext,
  } = props;
  const [isSeen, setIsSeen] = useState(props.isSeen ?? false);
  const [isExpanded, setIsExpanded] = useState(!isExpandable);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsExpanded(false));

  // If the bubble isExpandable the main bubble is shown on hover or focus
  const mainBubble = (
    <div
      className={classy(
        !isExpandable && className,
        classes.SpeechBubble,
        classes['SpeechBubble-main'],
        isExpandable && classes['SpeechBubble-main_expanded'],
        type === BubbleType.OutgoingMessage && classes['SpeechBubble-main_outgoing'],
      )}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className={classes['SpeechBubble-content']}>{content}</div>
      {!!onSeen && (
        <Button
          className={classes['SpeechBubble-seen']}
          onBlur={() => setIsExpanded(!isExpandable)}
          onClick={() => {
            const nextIsSeen = !isSeen;
            onSeen(nextIsSeen);
            setIsSeen(nextIsSeen);
          }}
          size="sm"
          text={true}
        >
          <i
            className={classy(
              'icon-check-circle',
              classes['SpeechBubble-main-icon'],
              isSeen && classes['SpeechBubble-main-icon_seen'],
            )}
          />
          {isSeen ? 'Seen' : 'Mark as Seen'}
        </Button>
      )}
      {!!subtext && <div className={classes['SpeechBubble-subtext']}>{subtext}</div>}
      <Tail
        className={classy(
          classes['SpeechBubble-tail'],
          type === BubbleType.OutgoingMessage && classes['SpeechBubble-tail_outgoing'],
        )}
      />
    </div>
  );

  const collapsedBubble = (
    <button
      className={classy(classes.SpeechBubble, classes['SpeechBubble-button'], classes.SpeechBubble_collapsed)}
      onClick={() => setIsExpanded(!isExpanded)}
      onFocus={() => setIsExpanded(true)}
      onMouseOver={() => setIsExpanded(true)}
      type="button"
    >
      <div className={classes['SpeechBubble_collapsed-content']}>{content}</div>
      <Tail
        className={classy(
          classes['SpeechBubble-tail'],
          type === BubbleType.OutgoingMessage && classes['SpeechBubble-tail_outgoing'],
        )}
      />
    </button>
  );

  const bubble = isExpandable ? (
    <div ref={ref} className={classy(className, classes['SpeechBubble-wrapper'])}>
      {collapsedBubble}
      {!!isExpanded && mainBubble}
    </div>
  ) : (
    mainBubble
  );

  return (
    <Flex align="end" gap="sm" justify={`${type === BubbleType.OutgoingMessage ? 'end' : 'start'}`} layout="row">
      {!!avatarProps && (
        <Avatar className={classy(classes['SpeechBubble-Avatar'])} {...avatarProps} circular={true}>
          {avatarProps.name}
        </Avatar>
      )}
      {bubble}
    </Flex>
  );
}

export default SpeechBubble;
