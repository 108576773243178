import type { $TSFixMe } from '@readme/iso';
import type { Column } from '@tanstack/react-table';

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Toggle from '@ui/Toggle';

import classes from '../style.module.scss';

export interface Props {
  column: Column<$TSFixMe, unknown>;
  columnId: string;
  findColumn: (id: string) => { column?: Column<$TSFixMe, unknown>; index?: number };
  moveColumn: (id: string, to: number) => void;
  onDrop: () => void;
}

interface DragItem {
  id: string;
  index: number;
  originalIndex: number;
  type: string;
}

export const ItemTypes = {
  COLUMN: 'column',
};

const DragColumn = ({ columnId, column, findColumn, moveColumn, onDrop }: Props) => {
  const { index: originalIndex } = findColumn(columnId);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.COLUMN,
      item: { id: columnId, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex: endIndex } = item;
        const didDrop = monitor.didDrop();

        if (!didDrop && endIndex !== undefined) {
          moveColumn(droppedId, endIndex);
        }
      },
    }),
    [columnId, moveColumn, originalIndex],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.COLUMN,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== columnId && originalIndex !== undefined) {
          moveColumn(draggedId, originalIndex);
        }
      },
      drop() {
        onDrop();
      },
    }),
    [columnId, moveColumn, onDrop, originalIndex],
  );

  const bem = useClassy(classes, 'ReactTable');

  return (
    <div ref={node => drag(drop(node))} style={{ opacity: isDragging ? 0 : 1 }}>
      <Flex align="stretch" gap={0}>
        <Toggle
          checked={column.getIsVisible()}
          className={`${bem('-columnSelector-label')} Menu-Item Menu-Item-alignIcon_left`}
          disabled={!column.getCanHide()}
          label={column?.columnDef?.header as string}
          onChange={column.getToggleVisibilityHandler()}
          type="checkbox"
        />

        <Button
          kind="secondary"
          size="sm"
          style={{
            cursor: 'grab',
          }}
          text
        >
          <Icon name="drag" />
        </Button>
      </Flex>
    </div>
  );
};

export default DragColumn;
