import type { ChartData, ChartDataset } from './types';
import type { Chart, ChartType, TooltipModel } from 'chart.js';

import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';
import useGraphTooltip from '@core/hooks/useGraphTooltip';
import obfuscateKey from '@core/utils/obfuscateKey';
import prettyNumber from '@core/utils/prettyNumber';
import shortUrl from '@core/utils/shortUrl';

import Box from '@ui/Box';
import Delta from '@ui/Delta';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';

import { EMPTY_VALUE } from './constants';
import classes from './style.module.scss';
import TooltipItem from './TooltipItem';
import { graphTypeToUnits } from './utils';

interface Props {
  chart: Chart;
  className?: string;
  currentDateRange?: string;
  data?: ChartData;
  groupFilterName?: string;
  isCompare?: boolean;
  theme?: 'default' | 'empty' | 'page-quality' | 'status-code-groups' | 'status-codes';
  title?: string;
  tooltip: TooltipModel<ChartType>;
}

function MetricsGraphTooltip({
  chart,
  className,
  currentDateRange,
  data = { datasets: [], labels: [], groups: [], isGrouped: false },
  tooltip,
  title = '',
  isCompare = false,
  theme,
  groupFilterName = '',
}: Props) {
  const bem = useClassy(classes, 'GraphTooltip');

  const { isVisible, style } = useGraphTooltip(chart, tooltip);

  const renderLabel = useCallback(
    (label: string, skipObfuscation = false) => {
      if (groupFilterName === 'Group ID' && !skipObfuscation) {
        return obfuscateKey(label, 'before', 4);
      }

      return shortUrl(label || EMPTY_VALUE);
    },
    [groupFilterName],
  );

  if (!isVisible) return null;

  // All status codes that this tooltip should display
  const { dataIndex } = tooltip.dataPoints[0];
  const label = tooltip.title[0];

  const { datasets: providedDatasets = [] } = data;

  const isThumbvote = title === 'Page Quality';
  const isMultiCompare = Object.keys(providedDatasets[0]).includes('group');

  const currentDatasets = providedDatasets.filter((d: ChartDataset) => !d.isComparison);
  const comparisonDatasets = providedDatasets.filter((d: ChartDataset) => d.isComparison);

  const comparisonText =
    currentDateRange && currentDateRange !== 'custom'
      ? `Compared to prior ${currentDateRange}`
      : 'Compared to prior period';

  const getTotalCount = (datasets: ChartDataset[]) =>
    datasets
      .map((entry: ChartDataset) => entry.data[dataIndex])
      .reduce((a: number, b: number) => Math.abs(a) + Math.abs(b), 0);
  const prettyWithUnits = (value: number) => `${prettyNumber(value)} ${graphTypeToUnits(title, value)}`;

  if (isMultiCompare || isThumbvote) {
    const totalCount = getTotalCount(currentDatasets);
    const comparisonCount = isCompare ? getTotalCount(comparisonDatasets) : null;

    const renderMultiCompareEntries = (entries: ChartDataset[]) => {
      const sortedEntries = entries.sort((a, b) => b.data[dataIndex] - a.data[dataIndex]);

      return sortedEntries.map(({ group, borderColor: color, data: entryData }: ChartDataset, i: number) => {
        const value = entryData[dataIndex];

        if (theme === 'status-code-groups') {
          // If group name is '0' it is a group of sucessful requests
          // otherwise it is a group of errors
          const isSuccess = group === '0';
          return (
            <TooltipItem
              key={`${group}_${i}`}
              isCondensed
              label={
                <Flex align="center" gap="xs" justify="start">
                  <HTTPStatus iconOnly status={`${isSuccess ? 2 : 4}XX`} />
                  <span>{isSuccess ? 'Success' : 'Error'}</span>
                </Flex>
              }
              value={prettyNumber(value)}
            />
          );
        }

        const colorBoxStyle = {
          background: color,
          width: '16px',
          height: '16px',
          borderRadius: 'var(--border-radius)',
        };

        return (
          <TooltipItem
            key={`${group}_${i}`}
            isCondensed
            label={
              <>
                <div style={colorBoxStyle} />
                <span>{renderLabel(group)}</span>
              </>
            }
            value={prettyNumber(value)}
          />
        );
      });
    };

    const getVoteCount = (entries: ChartDataset[], colorIndex = 0) =>
      Math.abs(entries.find((entry: ChartDataset) => entry.colorIndex === colorIndex)?.data[dataIndex] || 0);

    const renderThumbvoteEntries = (entries: ChartDataset[]) => {
      const upVoteCount = getVoteCount(entries, 1);
      const downVoteCount = getVoteCount(entries, 0);

      return (
        <>
          {Boolean(upVoteCount) && (
            <TooltipItem
              key="upvote"
              isCondensed
              label={
                <>
                  <i className="icon-thumbs-up-2" style={{ color: 'var(--green)' }} />
                  <span>Helpful</span>
                </>
              }
              value={prettyNumber(upVoteCount)}
            />
          )}
          {Boolean(downVoteCount) && (
            <TooltipItem
              key="downvote"
              isCondensed
              label={
                <>
                  <i className="icon-thumbs-down1" style={{ color: 'var(--red)' }} />
                  <span>Not Helpful</span>
                </>
              }
              value={prettyNumber(downVoteCount)}
            />
          )}
        </>
      );
    };

    return (
      <Box className={bem('&', className)} kind="pop" style={style}>
        <TooltipItem isStrong label={<span>{renderLabel(label, true)}</span>} value={prettyWithUnits(totalCount)} />

        {isMultiCompare ? renderMultiCompareEntries(currentDatasets) : renderThumbvoteEntries(currentDatasets)}

        {!!isCompare && (
          <>
            <hr className={bem('-divider')} />
            <TooltipItem isStrong label={comparisonText} value={prettyWithUnits(comparisonCount || 0)} />
            {isMultiCompare
              ? renderMultiCompareEntries(comparisonDatasets)
              : renderThumbvoteEntries(comparisonDatasets)}
          </>
        )}
      </Box>
    );
  }

  const currentValue = currentDatasets[0].data[dataIndex];
  const comparisonValue = comparisonDatasets[0]?.data[dataIndex];

  return (
    <Box className={bem('&', className)} kind="pop" style={style}>
      <TooltipItem
        isStrong
        label={<span>{renderLabel(label)}</span>}
        value={
          <>
            {!!isCompare && <Delta from={comparisonValue} to={currentValue} />}
            {prettyWithUnits(currentValue)}
          </>
        }
      />

      {!!isCompare && (
        <>
          <hr className={bem('-divider')} />
          <TooltipItem isStrong label={comparisonText} value={prettyWithUnits(comparisonValue)} />
        </>
      )}
    </Box>
  );
}

// Don't memo me, I am not pure!
export default MetricsGraphTooltip;
