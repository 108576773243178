const formatter = Intl.NumberFormat('en', { notation: 'compact' });

/**
 * Converts numbers over 1e3 into abbreviated form like 1.2k or 2.8m
 * @param {Number} num
 * @returns {String}
 */
function abbreviatedNumber(num: number, precision = 1) {
  if (typeof num !== 'number') return num;
  // Get the correct power of 10; for example 1000 woud be 3 because 1e3 is 1000
  const zeroCount = Math.abs(num).toString().length - 1;
  // Ignore numbers less than 1000
  if (zeroCount < 3) return num.toString();

  // Get the units (i.e. K, M, B) from the formatter
  const units = formatter.format(Number(`1e${zeroCount}`)).slice(-1);
  // Represents what we need to multiply the number by to ensure it has the correct power of 10
  const powerOfTenNormalizer = parseFloat(`1e${zeroCount % 3}`) / parseFloat(`1e${zeroCount}`);
  // Ensure the correct digit count; round the float to a specific number of decimals; cast it back to a number to drop any trailing zero decimals
  const abbreviatedNum = +(num * powerOfTenNormalizer).toFixed(precision);

  return `${abbreviatedNum}${units}`;
}

export default abbreviatedNumber;
