import type { ColumnOrderState, VisibilityState } from '@tanstack/react-table';

import { useState, useEffect, useCallback } from 'react';

import type { TablePrefsKey } from '@core/enums/tablePrefs';
import useIsDocumentReady from '@core/hooks/useIsDocumentReady';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { safelyParseJSON, safelyStringifyJSON } from '@core/utils/json';

interface Props {
  /** Default order of columns */
  defaultOrder?: ColumnOrderState;
  /** Default visibility of columns */
  defaultVisibility?: VisibilityState;
  /** Name of the LocalStorage preferences key for table */
  prefsName: TablePrefsKey;
  /** Optional prop to disable saving to LocalStorage */
  skipLocalStorageUpdate?: boolean;
}

/**
 *
 * Util hook for column visibility and order
 * Pulls and saves tablePrefs to LocalStorage under '@readme:tablePrefs'
 */
function useTablePreferences({
  defaultVisibility = {},
  defaultOrder = [],
  prefsName,
  skipLocalStorageUpdate = false,
}: Props) {
  const isDocumentReady = useIsDocumentReady();
  const storage = useLocalStorage();

  const storedTablePrefs = safelyParseJSON(storage.getItem('tablePrefs'))?.[prefsName];

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => {
    return storedTablePrefs?.columnVisibility || defaultVisibility;
  });

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(() => {
    return storedTablePrefs?.columnOrder || defaultOrder;
  });

  // Maintain any order or visibility changes in localStorage
  useEffect(() => {
    if (skipLocalStorageUpdate || !isDocumentReady || !prefsName) return;

    const storedColumnOrder = safelyParseJSON(storage.getItem('tablePrefs')) || {};
    const json = safelyStringifyJSON({
      ...storedColumnOrder,
      [prefsName]: {
        columnOrder,
        columnVisibility,
      },
    });

    if (json !== null) {
      storage.setItem('tablePrefs', json);
    }
  }, [columnVisibility, columnOrder, isDocumentReady, prefsName, skipLocalStorageUpdate, storage]);

  // Reset column and visibility back to stored or default prefs
  const resetTablePrefs = useCallback(() => {
    setColumnVisibility(storedTablePrefs?.columnVisibility || defaultVisibility);
    setColumnOrder(storedTablePrefs?.columnOrder || defaultOrder);
  }, [defaultOrder, defaultVisibility, storedTablePrefs]);

  return { columnOrder, columnVisibility, resetTablePrefs, setColumnOrder, setColumnVisibility };
}

export default useTablePreferences;
