import React, { useCallback, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';

import useClassy from '@core/hooks/useClassy';
import type { MetricsFilter, MetricsFilterType } from '@core/types/metrics';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider } from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import Filter from '../Filter';
import styles from '../style.module.scss';

const VIRTUAL_LIST_ITEM_SIZE = 30;

interface Props {
  /** Clear filter type callback */
  clearFilterType: (filterType: MetricsFilterType) => void;
  /** Clear search input callback */
  clearSearch: () => void;
  /** Total count of filters */
  count: number;
  /** The filter type (based on Column enum) */
  filterType: MetricsFilterType;
  /** All More... filters data for the given type (from server) */
  filters: MetricsFilter['data'];
  /** Search input value */
  moreSearch: string;
  /** Display name for filter group */
  name: string;
  /** Search input change callback */
  onSearchChange: (value: string) => void;
  /** Map of selected filters */
  selectedFiltersMap: Map<string, boolean>;
  /** Set filter callback */
  setFilter: (key: MetricsFilterType, value: string) => void;
  /** Whether to show the reset button */
  showResetButton: boolean;
  /** Theme prop for subcomponents of Filters like <Menu> */
  theme?: 'dark';
  /** Unset filter callback */
  unsetFilter: (key: MetricsFilterType, value: string) => void;
}

const MoreDropdown = ({
  clearFilterType,
  clearSearch,
  count,
  filterType,
  filters,
  moreSearch,
  name,
  onSearchChange,
  selectedFiltersMap,
  setFilter,
  showResetButton,
  unsetFilter,
  theme,
}: Props) => {
  const bem = useClassy(styles, 'FilterGroup');

  const makeItemKey = useCallback((index, data) => {
    return `more-filter-${data[index].name}`;
  }, []);

  const listHeight = useMemo(() => {
    if (!filters.length) return 0;
    return filters.length >= 5 ? VIRTUAL_LIST_ITEM_SIZE * 5 : VIRTUAL_LIST_ITEM_SIZE * filters.length;
  }, [filters.length]);

  return (
    <Dropdown
      align="bottom"
      appendTo={() => document.body}
      justify="start"
      lazyMountContent
      offset={[20, 0]}
      onBlur={clearSearch}
      sticky
    >
      <Button className={bem('-more')} kind="secondary" size="sm" text>
        More
        <Icon className={bem('-moreIcon')} name="more-horizontal" />
        {!!count && <div className={bem('-moreCount')}>{count.toLocaleString()}</div>}
      </Button>

      <Menu className={bem('-moreDropdownMenu')} theme={theme} {...(theme === 'dark' && { 'data-color-mode': 'dark' })}>
        <MenuHeader>Filter by {name}</MenuHeader>
        <MenuItem focusable={false}>
          <Input
            id="filter"
            name="filter"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              onSearchChange(value);
            }}
            placeholder="Filter"
            size="sm"
            value={moreSearch}
          />
        </MenuItem>

        <VirtualList
          height={listHeight}
          itemCount={filters.length}
          itemData={filters}
          itemKey={makeItemKey}
          itemSize={VIRTUAL_LIST_ITEM_SIZE}
          width="100%"
        >
          {({ index, style }) => {
            const f = filters[index];
            const isChecked = selectedFiltersMap.has(f.name);
            const showCount = isChecked || f.count > 0;

            return (
              <MenuItem className={bem('-moreMenuItem')} style={style}>
                <Filter
                  filter={f}
                  filterType={filterType}
                  isChecked={isChecked}
                  setFilter={setFilter}
                  showCount={showCount}
                  unsetFilter={unsetFilter}
                />
              </MenuItem>
            );
          }}
        </VirtualList>

        {moreSearch !== '' && filters.length === 0 && (
          <MenuItem focusable={false}>
            <div className={bem('-moreSearchNoResults')}>No results found for &apos;{moreSearch}&apos;</div>
          </MenuItem>
        )}

        <MenuDivider />

        {!!showResetButton && (
          <MenuItem
            color="red"
            onClick={() => {
              clearFilterType(filterType);
            }}
          >
            Reset to Default
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
};

export default MoreDropdown;
