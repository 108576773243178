import React, { useCallback, useMemo } from 'react';

import { Column } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import type { MetricsFilters, MetricsFilter, MetricsFilterType, APIRequestData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import Icon from '@ui/Icon';
import Input from '@ui/Input';
import APIRequestsGraph from '@ui/Metrics/APIRequestsGraph';
import FilterGroup from '@ui/Metrics/Filters/FilterGroup';
import Spinner from '@ui/Spinner';

import styles from './style.module.scss';

/** Mapping of filter type to display name */
export const filterDisplayNames = {
  [Column.APIRequests]: columnHeaders[Column.APIRequests],
  [Column.Path]: 'Endpoint', // Override from columnHeaders to be more specific
  [Column.Method]: columnHeaders[Column.Method],
  [Column.Status]: columnHeaders[Column.Status],
  [Column.UserAgent]: columnHeaders[Column.UserAgent],
};

interface Props {
  /** Currently applied filters (from client) */
  activeFilters: MetricsFilters;
  /** All available filters (from server) */
  allFiltersData: MetricsFilter[];
  apiRequestTrends?: APIRequestData;
  /** Clear a filter callback */
  clearFilter: (key: MetricsFilterType, value: string) => void;
  /** Currently applied filters based on current query params (from server) */
  data: MetricsFilter[];
  /** Whether the route has an API key in the URL for key insights view */
  hasAPIKeyInRoute?: boolean;
  /** Whether any filters have changed (been selected) from initial filters */
  hasChangedFromInitialFilters: boolean;
  /** Whether to display the request graph */
  hideAPIRequestsGraph?: boolean;
  /** Whether to hide search input */
  hideSearch?: boolean;
  /** Whether fetch for all available filters is loading */
  isLoading?: boolean;
  /** Set filters callback */
  setFilters: (payload: { filters: Partial<MetricsFilters> }) => void;
  /** Theme override prop for subcomponents of Filters like <Menu> */
  theme?: 'dark';
}

const Filters: React.FC<Props> = ({
  activeFilters,
  allFiltersData,
  apiRequestTrends,
  children,
  clearFilter,
  data,
  hasAPIKeyInRoute = false,
  hasChangedFromInitialFilters,
  hideAPIRequestsGraph = false,
  hideSearch = false,
  isLoading,
  setFilters,
  theme,
}) => {
  const bem = useClassy(styles, 'Filters');

  const updateSearch = useDebounced((value: string) => {
    setFilters({ filters: { userSearch: value || null, page: 0 } });
  }, 300);

  const handleSearchChange = useCallback(
    e => {
      const { value } = e.target;
      updateSearch(value.trim());
    },
    [updateSearch],
  );

  const setFilter = useCallback(
    (key, value) => {
      const currentFilters = { ...activeFilters };
      const currentFiltersValue = currentFilters[key] as string[];

      let newFilterValue = [value];

      // If there are already keys on this filter set, we can concat value
      if (currentFiltersValue && currentFiltersValue?.length > 0) {
        newFilterValue = currentFiltersValue.concat([value]);
      }

      setFilters({ filters: { [key]: newFilterValue, page: 0 } });
    },
    [activeFilters, setFilters],
  );

  const unsetFilter = useCallback(
    (key, value) => {
      clearFilter(key, value);
    },
    [clearFilter],
  );

  const clearFilterType = useCallback(
    filterType => {
      setFilters({ filters: { [filterType]: [], page: 0 } });
    },
    [setFilters],
  );

  // Only display spinner for initial filters data request
  const showLoading = useMemo(() => {
    return isLoading && !allFiltersData;
  }, [isLoading, allFiltersData]);

  const filters: MetricsFilter[] = allFiltersData || [
    { name: 'apiRequests', data: [] },
    { name: 'path', data: [] },
    { name: 'method', data: [] },
    { name: 'status', data: [] },
    { name: 'useragent', data: [] },
  ];

  const hasFilters = filters.some(filter => filter.data.length > 0);

  const showSearchInput = !hasAPIKeyInRoute && !hideSearch;

  return (
    <section className={bem('&')}>
      {/* Optional top area for any children passed to the component */}
      {!!children && <div className={bem('-top')}>{children}</div>}

      {!!showSearchInput && (
        <div className={bem('-search', !hasFilters && '-search_disabled')}>
          <Input
            disabled={!hasFilters}
            id="userSearch"
            name="userSearch"
            onChange={handleSearchChange}
            onClear={() => updateSearch('')}
            placeholder="Users, API Keys, Companies"
            prefix={<Icon name="search" />}
            size="sm"
            value={activeFilters?.userSearch || ''}
          />
        </div>
      )}

      {showLoading ? (
        <div className={bem('_loading')}>
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          <APIRequestsGraph
            hideAPIRequestsGraph={hideAPIRequestsGraph}
            requestData={apiRequestTrends}
            useDarkMode={theme === 'dark'}
          />
          {filters.map((filter, i) => (
            <FilterGroup
              key={`filter-group-${filter.name}`}
              activeFilters={activeFilters}
              activeFiltersData={data ? data[i]?.data : []}
              allFiltersData={filter.data}
              clearFilterType={clearFilterType}
              filterType={filter.name}
              hasChangedFromInitialFilters={hasChangedFromInitialFilters}
              name={filterDisplayNames[filter.name]}
              setFilter={setFilter}
              theme={theme}
              unsetFilter={unsetFilter}
            />
          ))}
        </>
      )}
    </section>
  );
};

export { default as Filter } from './Filter';
export { default as FilterGroup } from './FilterGroup';
export { default as FilterContentWithTooltip } from './FilterContentWithTooltip';
export default Filters;
