import type { HTTPHeaderDescription } from '@readme/http-headers/dist/types';
import type { $TSFixMe } from '@readme/iso';
import type { HarRequest } from '@readme/oas-to-snippet/types';

import getHeader from '@readme/http-headers';
import rdmd from '@readme/markdown';
import oasToSnippet from '@readme/oas-to-snippet';
import Tippy from '@tippyjs/react';
import copy from 'clipboard-copy';
import httpsnippetClientAPIPlugin from 'httpsnippet-client-api';
import React, { useEffect, useState, useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useFormatResponseLogCode, { cleanStringify } from '@core/hooks/useFormatResponseLogCode';
import type { APIKeyLogResponse, Header } from '@core/types/metrics';
import { upperCamelCase } from '@core/utils/metrics';
import { hideOnEsc } from '@core/utils/tippy';

import APIMethod from '@ui/API/Method';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import CopyToClipboard from '@ui/CopyToClipboard';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider } from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';
import BasicHeaderReveal from '@ui/Metrics/BasicHeaderReveal';
import Spinner from '@ui/Spinner';
import Tabs from '@ui/Tabs';
import Title from '@ui/Title';
import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';

const LOG_DETAIL_POPOVER_Z_INDEX = 99999;
const HEADER_TOOLTIP_Z_INDEX = 100000;

interface Props {
  /** If log request has an error, we'll show general error message in popover */
  hasError?: boolean;
  /* Whether popover is being shown in Hub (dictates endpoint docs link handling) */
  isHub?: boolean;
  /** The log data */
  log: APIKeyLogResponse['log'];
  /** Popover onClose callback invoked on close button */
  onClose: (targetElement?: HTMLElement | null) => void;
  /** Whether popover is open (controlled) */
  open: boolean;
}

type LogDetailProps = Omit<Props, 'open'>;

type ExtendedHeader = Header & HTTPHeaderDescription & { fromReadMe?: boolean };

interface HeadersProps {
  className?: string;
  headers: ExtendedHeader[];
}

interface HeaderSectionProps {
  filter: string;
  headers: {
    documented: ExtendedHeader[];
    undocumented: ExtendedHeader[];
  };
  setHeaderFilter: (filter: string) => void;
}

const CopyItemLabel = ({ label }: { label: string }) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  if (label !== 'Copied') {
    return <span>{label}</span>;
  }

  return (
    <span className={bem('&-copiedItem')}>
      <i className="icon-check1" />
      {label}
    </span>
  );
};

const TruncatedBadge = ({ bodyTrimmed = false }: { bodyTrimmed: boolean }) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  if (!bodyTrimmed) return null;

  return (
    <Tooltip content="This log's body has exceeded a 10k character limit and has been truncated.">
      <Badge allCaps className={bem('&-body-heading-badge')} kind="alert">
        Truncated
      </Badge>
    </Tooltip>
  );
};

const HeaderTooltip = ({ header }: { header: ExtendedHeader }) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  const headerName = upperCamelCase(header.name);
  const description = header.markdown
    ? rdmd(header.markdown, {
        copyButtons: false,
      })
    : header.description;

  return (
    <Menu className={bem('&-headers-tooltip-menu')} theme="dark">
      <MenuHeader>{headerName}</MenuHeader>
      <MenuItem className={bem('&-headers-tooltip-menu-description')} description={description} focusable={false} />
      {!!header.link && (
        <>
          <MenuDivider />
          <MenuItem href={header.link} TagName="a" target="_blank">
            {header.fromReadMe ? 'See docs' : 'MDN'}
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

const Headers = ({ className = '', headers }: HeadersProps) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  const refs = useRef<(HTMLElement | null)[]>([]);
  const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);

  return (
    <>
      {headers?.map((header, i) => (
        <React.Fragment key={header.name}>
          {header?.description?.length ? (
            <dt
              ref={el => {
                refs.current[i] = el;
              }}
              className={bem('&-key-value-list-name-with-tooltip', className)}
              onClick={() => {
                setTooltipIndex(i);
              }}
              onKeyDown={() => {}} // empty function to avoid eslint error
            >
              {header.name}
            </dt>
          ) : (
            <dt className={className}>{header.name}</dt>
          )}
          <dd className={className}>
            <BasicHeaderReveal header={header} theme="dark" />
          </dd>
        </React.Fragment>
      ))}

      <Tippy
        appendTo={() => document.body}
        arrow={false}
        content={tooltipIndex !== null ? <HeaderTooltip header={headers[tooltipIndex]} /> : null}
        interactive
        onClickOutside={() => {
          setTooltipIndex(null);
        }}
        placement="bottom-end"
        reference={refs.current[tooltipIndex || 0]}
        visible={tooltipIndex !== null}
        zIndex={HEADER_TOOLTIP_Z_INDEX}
      />
    </>
  );
};

const HeadersList = ({
  filter,
  headers,
}: {
  filter: HeaderSectionProps['filter'];
  headers: HeaderSectionProps['headers'];
}) => {
  const bem = useClassy(styles, 'LogDetailPopover');
  const [headersCollapsed, setHeadersCollapsed] = useState(true);

  // If there are more than 5 headers enable collapsing
  const minNumHeadersToCollapse = 5;

  // Filter headers down by any active search
  const visibleDocumentedHeaders = headers?.documented.filter(
    header => header.name.includes(filter) || header.value.includes(filter),
  );

  const visibleUndocumentedHeaders = headers?.undocumented.filter(
    header => header.name.includes(filter) || header.value.includes(filter),
  );

  const allowCollapse = visibleDocumentedHeaders.length + visibleUndocumentedHeaders.length > minNumHeadersToCollapse;

  if (!!filter && !visibleDocumentedHeaders?.length && !visibleUndocumentedHeaders?.length)
    return (
      <Flex align="center" className={bem('&-headers-filter-no-results')} justify="center">
        No headers found matching filter
      </Flex>
    );

  return (
    <div className={bem('&-collapse')}>
      <dl className={bem('&-key-value-list', allowCollapse && headersCollapsed && '&-collapse-height')}>
        <Headers headers={visibleDocumentedHeaders} />
        <Headers className={bem('&-key-value-list-muted')} headers={visibleUndocumentedHeaders} />
      </dl>
      {!!allowCollapse && (
        <div className={bem('&-collapse-overlay', !headersCollapsed && '&-collapse-open')}>
          <Button
            className={bem('&-collapse-bttn')}
            ghost
            kind="contrast"
            onClick={() => setHeadersCollapsed(!headersCollapsed)}
            size="xs"
          >
            {headersCollapsed ? 'Show All' : 'Hide'}
          </Button>
        </div>
      )}
    </div>
  );
};

const HeadersSection = ({ filter, headers, setHeaderFilter }: HeaderSectionProps) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  return (
    <div className={bem('&-headers')}>
      <Flex align="center" className={bem('&-body-header')}>
        <Title className={bem('&-body-heading')}>Headers</Title>
        {(headers?.documented.length > 0 || headers?.undocumented.length > 0) && (
          <Input
            id="headerFilter"
            name="headerFilter"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setHeaderFilter(value);
            }}
            placeholder="Filter"
            prefix={<Icon name="search" />}
            size="xs"
            value={filter}
            wrapperClassName={bem('-headers-filter')}
          />
        )}
      </Flex>
      <HeadersList filter={filter} headers={headers} />
    </div>
  );
};

export const X_DOC_NAME = 'x-documentation-url';
const X_DOC_HEADER = {
  description:
    'Added to `response.headers` by the ReadMe Metrics SDK. This header provides an easy link for accessing an API log in your documentation.',
  link: 'https://docs.readme.com/main/docs/sending-logs-to-readme-with-nodejs#documentation-url',
  fromReadMe: true,
};

const LogDetail = ({ hasError = false, isHub = false, log, onClose }: LogDetailProps) => {
  const bem = useClassy(styles, 'LogDetailPopover');

  const {
    formattedCode: responseBody,
    language: responseLanguage,
    setFormattedLanguage,
  } = useFormatResponseLogCode(log?.responseBody?.text, 'json');

  const requestBody = useMemo(() => {
    try {
      if (!log?.requestBody) return '';
      const { text, params } = log.requestBody;

      if (!text?.length && !params?.length) return '';

      if (text?.length) {
        try {
          const body = JSON.parse(text);
          if (!Object.keys(body)?.length) return text;
          return cleanStringify(body);
        } catch (e) {
          return text;
        }
      }

      if (params?.length) {
        const body = params.reduce((acc: Record<string, string>, p: { name: string; value: string }) => {
          const { name, value } = p;
          acc[name] = value;
          return acc;
        }, {});

        return cleanStringify(body);
      }

      return cleanStringify(log.requestBody?.text || '');
    } catch (e) {
      return '';
    }
  }, [log?.requestBody]);

  const [headerFilter, setHeaderFilter] = useState('');

  const [requestCurlCopied, setRequestCurlCopied] = useState('Copy cURL Request');
  const [activeTab, setActiveTab] = useState('Request');

  let requestCurl: string = '';
  if (log?.request) {
    const { code } = oasToSnippet(null as $TSFixMe, null as $TSFixMe, null as $TSFixMe, null as $TSFixMe, 'shell', {
      harOverride: log.request as unknown as HarRequest,
      plugins: [httpsnippetClientAPIPlugin],
    });
    requestCurl = code || '';
  }

  const copyItemDuration = 1500;
  const copyItemLabel = 'Copied';
  const copyItemFailedLabel = 'Unable to Copy';

  const popoverRef = useRef<HTMLDivElement>(null);

  const date = new Date(log?.startedDateTime || Date.now());
  const formattedTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;

  useEffect(() => {
    // If log changes, set activeTab back to 'Response'
    setActiveTab('Request');

    if (log) {
      // Autofocus popover on open
      popoverRef?.current?.focus();
    }
  }, [log, setActiveTab]);

  useEffect(() => {
    if (log?.responseBody?.mimeType) setFormattedLanguage(log.responseBody.mimeType);
  }, [log?.responseBody?.mimeType, setFormattedLanguage]);

  const headers = useMemo(() => {
    const headerBase = { documented: [], undocumented: [] } as HeaderSectionProps['headers'];

    if (!log) return headerBase;

    const currentHeaders = activeTab === 'Request' ? log.requestHeaders : log.responseHeaders;

    return currentHeaders
      .sort((a, b) => a.name?.localeCompare(b.name))
      .reduce((acc, h) => {
        const readmeHeader = h?.name === X_DOC_NAME;
        const headerMeta = readmeHeader ? X_DOC_HEADER : getHeader(h?.name);
        const decoratedheader = { ...h, ...headerMeta };

        // `rdme-doc` is added as a header decorated via the Metrics service
        // when a header is found in a customer's OAS
        // https://github.com/readmeio/readme-metrics-api/blob/next/src/clickhouse/models/request.ts#L271
        if (h.comment === 'rdme-doc' || readmeHeader) acc.documented.push(decoratedheader);
        else acc.undocumented.push(decoratedheader);

        return acc;
      }, headerBase);
  }, [activeTab, log]);

  const requestQueryParams = useMemo(() => {
    if (!log) return [];
    return log.queryString;
  }, [log]);

  const endpointDocsUrl = useMemo(() => {
    if (!log || !log?.['oas.operationId']) return '';

    const operationId = log['oas.operationId'].toLowerCase();

    return isHub ? `/reference/${operationId}?metricId=${log?.id}` : `/go/${log?.subdomain}?redirect=/logs/${log?.id}`;
  }, [isHub, log]);

  const handleCopyRequestCurl = () => {
    copy(requestCurl)
      .then(() => setRequestCurlCopied(copyItemLabel))
      .catch(() => setRequestCurlCopied(copyItemFailedLabel))
      .then(() => setTimeout(() => setRequestCurlCopied('Copy cURL Request'), copyItemDuration));
  };

  const codeSnippetOptions = {
    foldGutter: true,
    highlightMode: true,
    readOnly: true,
    extraKeys: { Tab: false, 'Shift-Tab': false },
    dark: true,
  };

  const isGetRequest = log?.method === 'GET';

  if (hasError) {
    return (
      <section className={bem('&')}>
        <div className={bem('&-error')}>Something went wrong while retrieving this log.</div>
      </section>
    );
  }

  return (
    <section className={bem('&', !!log && '_expanded')}>
      {log ? (
        <div ref={popoverRef} tabIndex={-1}>
          <div className={bem('&-header')}>
            <div className={bem('&-header-top')}>
              <Flex align="baseline" className={bem('&-header-metadata')} gap="sm" justify="start">
                <Flex align="center" gap="xs" justify="start">
                  <HTTPStatus className={bem('&-header-status')} status={log.status} />
                  <APIMethod fixedWidth type={log?.method} />
                </Flex>
                <Title className={bem('&-header-title')} level={1}>
                  <Tooltip content={log.url} title>
                    <span>{log.normalizedPath}</span>
                  </Tooltip>
                </Title>
              </Flex>
              <Flex align="center" className={bem('&-header-subheading')} gap="xs" justify="start">
                <strong>ID:</strong> <span className={'-header-metadata-id'}>{log.id}</span>
                <CopyToClipboard className={bem('-copy-to-clipboard')} text={log.id} />
              </Flex>
              <Flex align="center" className={bem('&-header-subheading')} gap="xs" justify="start">
                {formattedTime}
                <span> &bull;</span>
                {log?.group?.email || log?.group?.label}

                <Dropdown appendTo={() => document.body} clickInToClose sticky trigger="click">
                  <Button aria-label="More actions" kind="contrast" outline size="xs">
                    <Icon aria-label="More ellipsis" name="more-vertical" />
                  </Button>
                  <Menu theme="dark">
                    <MenuItem
                      icon={<Icon name="terminal" />}
                      onClick={e => {
                        e.stopPropagation();
                        handleCopyRequestCurl();
                      }}
                    >
                      <CopyItemLabel label={requestCurlCopied} />
                    </MenuItem>
                    {/* Only show link to docs if we have an operationId */}
                    {!!endpointDocsUrl && (
                      <MenuItem href={endpointDocsUrl} icon={<Icon name="file" />} TagName="a" target="_blank">
                        Endpoint Docs
                      </MenuItem>
                    )}
                  </Menu>
                </Dropdown>
              </Flex>
            </div>
            <Tabs
              className={{ root: bem('&-tabs'), tabs: bem('&-tabs-list') }}
              onClick={tab => {
                setActiveTab(tab);
                setHeaderFilter('');
              }}
            >
              <div data-label="Request" />
              <div data-label="Response" />
            </Tabs>
            <Button className={bem('&-close-btn')} ghost kind="contrast" onClick={() => onClose()} size="sm">
              <i className="icon-x" />
            </Button>
          </div>

          <div className={bem('&-body')}>
            <div className={bem('&-content')}>
              {/** Request / Response Headers */}
              <HeadersSection filter={headerFilter} headers={headers} setHeaderFilter={setHeaderFilter} />

              {/** Response Body */}
              {activeTab === 'Response' && responseBody ? (
                <CodeSnippet
                  code={responseBody}
                  header={
                    <Flex align="center" className={bem('&-body-header')}>
                      <Title className={bem('&-body-heading')} level={2}>
                        Body
                        <TruncatedBadge bodyTrimmed={!!log.bodyTrimmed} />
                      </Title>
                      <CopyToClipboard className={bem('-copy-to-clipboard')} shift="start" text={responseBody} />
                    </Flex>
                  }
                  language={responseLanguage || 'json'}
                  options={codeSnippetOptions}
                />
              ) : (
                <>
                  {/** Query Params */}
                  {requestQueryParams?.length > 0 && (
                    <div className={bem('&-requestQueryParams')}>
                      <Flex align="center" className={bem('&-body-header')}>
                        <Title className={bem('&-body-heading')} level={2}>
                          Query Parameters
                        </Title>
                      </Flex>
                      <dl className={bem('&-key-value-list')}>
                        {requestQueryParams?.map(param => (
                          <React.Fragment key={param.name}>
                            <dt>{param.name}</dt>
                            <dd>{param.value}</dd>
                          </React.Fragment>
                        ))}
                      </dl>
                    </div>
                  )}

                  {/** Request Body */}
                  {!isGetRequest && (
                    <CodeSnippet
                      code={requestBody}
                      header={
                        <Flex align="center" className={bem('&-body-header')}>
                          <Title className={bem('&-body-heading')} level={2}>
                            Body
                            <TruncatedBadge bodyTrimmed={!!log.bodyTrimmed} />
                          </Title>
                          <CopyToClipboard className={bem('-copy-to-clipboard')} shift="start" text={requestBody} />
                        </Flex>
                      }
                      language={'json'}
                      options={codeSnippetOptions}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={bem('&-loading')}>
          <Spinner size="lg" />
        </div>
      )}
    </section>
  );
};

const LogDetailPopover = ({ hasError, isHub, log, open, onClose }: Props) => {
  const ref = useRef(null);

  return (
    <>
      {/* This div is what we're attaching the popover to, so it sits fixed at the right side of viewport "over" any elements */}
      <div ref={ref} style={{ position: 'fixed', top: 'var(--md)', right: '0px' }} />

      <Tippy
        appendTo={() => document.body}
        arrow={false}
        content={<LogDetail hasError={hasError} isHub={isHub} log={log} onClose={onClose} />}
        interactive
        maxWidth={450}
        offset={[0, 10]}
        onClickOutside={(instance, event) => {
          const targetElement = event?.target as HTMLElement | null;

          // Check if the click is inside the popover
          const isInnerPopoverClick = targetElement && targetElement.closest('.tippy-content');

          // Prevent closing when clicking on nested popover menu items
          if (isInnerPopoverClick) {
            return;
          }

          onClose(targetElement);
        }}
        placement="left-start"
        plugins={[hideOnEsc]}
        popperOptions={{
          strategy: 'fixed',
        }}
        reference={ref}
        visible={open}
        zIndex={LOG_DETAIL_POPOVER_Z_INDEX}
      />
    </>
  );
};

export default LogDetailPopover;
