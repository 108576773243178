import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import classes from './style.module.scss';

function getDelta(from, to) {
  const delta = Math.round(((to - from) / from) * 100);
  // If both numbers are zero then return zero
  if (Number.isNaN(delta)) return 0;
  // If starting point is zero then return 100
  if (!Number.isFinite(delta)) return 100;

  return delta;
}

function Delta(props) {
  const {
    className,
    from,
    isColorCoded = true,
    isInverted = false,
    isPercentage = true,
    isSigned = true,
    to,
    text,
  } = props;
  const delta = props.delta || getDelta(from, to);
  const isDecrease = delta < 0;

  return (
    <div
      className={classy(
        className,
        classes.Delta,
        (isInverted ? isDecrease : !isDecrease) && isColorCoded && classes.Delta_positive,
        (isInverted ? !isDecrease : isDecrease) && isColorCoded && classes.Delta_negative,
      )}
    >
      {!!isSigned && (
        <span>
          {delta >= 0 ? '+' : ''}
          {delta < 0 ? '-' : ''}
        </span>
      )}
      <span className={classes['Delta-number']}>{Math.abs(delta)}</span>

      {!!isPercentage && <span>&#37;</span>}
      {!!text && (
        <>
          {' '}
          <span>{text}</span>
        </>
      )}
    </div>
  );
}

Delta.propTypes = {
  className: PropTypes.string,
  delta: PropTypes.number,
  from: PropTypes.number,
  isColorCoded: PropTypes.bool,
  isInverted: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isSigned: PropTypes.bool,
  text: PropTypes.string,
  to: PropTypes.number,
};

export default Delta;
