import type { RenderExternalTooltipReturnType } from '@core/utils/graphTooltip';

const parseOptions = ({
  type,
  isCondensed,
  isGrouped,
  groupsCount,
  isStacked,
  tooltip,
  hideAxis,
  aspectRatio,
  isInteractive,
  scaleYTicksCallback,
}: {
  aspectRatio?: number;
  groupsCount: number;
  hideAxis: boolean;
  isCondensed: boolean;
  isGrouped: boolean;
  isInteractive: boolean;
  isStacked: boolean;
  scaleYTicksCallback?: (labelValue: string | null) => string;
  tooltip: RenderExternalTooltipReturnType;
  type: string;
}) => {
  const condensedAspectRatio = 3;
  const regularAspectRatio = 2;

  const defaultAspectRatio = aspectRatio || (isCondensed ? condensedAspectRatio : regularAspectRatio);
  const barChartAspectRatio = isGrouped && groupsCount > 16 ? regularAspectRatio : defaultAspectRatio;

  const indexAxis = type === 'bar' && isGrouped ? 'y' : 'x';

  const baseOpts = {
    animation: false,
    aspectRatio: defaultAspectRatio,
    clip: false,
    hover: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: hideAxis ? 8 : 0,
    },
    plugins: {
      tooltip: isInteractive
        ? {
            axis: indexAxis,
            mode: 'index',
            intersect: false,
            enabled: false,
            external: tooltip,
            xAlign: 'left',
            yAlign: 'center',
          }
        : null,
      ...(type === 'line' && isInteractive
        ? {
            crosshair: {},
          }
        : {
            crosshair: false,
          }),
    },
    scales: {
      x: {
        display: !hideAxis,
        grid: {
          drawBorder: 0,
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 50,
          color: '#637288',
          maxRotation: 0,
          maxTicksLimit: isCondensed ? 3 : undefined,
        },
      },
      y: {
        beginAtZero: true,
        display: !hideAxis,
        grid: {
          drawBorder: 0,
        },
        ticks: {
          color: '#637288',
          count: isCondensed ? 3 : undefined,
          // Optional callback for formatting y-axis ticks
          ...(!!scaleYTicksCallback && {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback(val: any): string {
              // @ts-ignore
              const labelValue = this.getLabelForValue(val); // getLabelForValue exists within Chart instance
              return scaleYTicksCallback(labelValue);
            },
          }),
        },
      },
    },
    ...(!isInteractive && { events: [] }),
  };

  let opts = {};

  switch (type) {
    case 'bar':
      opts = {
        ...baseOpts,
        aspectRatio: barChartAspectRatio,
        indexAxis,
        scales: {
          x: {
            stacked: isStacked,
            grid: {
              drawBorder: 0,
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 50,
              color: '#637288',
              maxRotation: 0,
              maxTicksLimit: isCondensed ? 3 : undefined,
            },
          },
          y: {
            stacked: isStacked,
            ticks: {
              autoSkipPadding: 0,
              count: isCondensed ? 3 : undefined,
              ...(baseOpts.scales.y.ticks.callback && { callback: baseOpts.scales.y.ticks.callback }),
            },
          },
        },
        type: 'bar',
      };
      break;

    case 'line':
    default:
      opts = baseOpts;
      break;
  }

  return opts;
};

export default parseOptions;
