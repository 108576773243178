import React from 'react';

function Tail({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      fill="none"
      height="23"
      viewBox="0 0 23 23"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3573 10.9153C10.3573 7.01695 8.96988 0 8.96988 0L22.8438 16.7627C15.2645 21.2921 9.95735 22.7404 0.84375 23C4.80664 19.837 10.3573 14.8136 10.3573 10.9153Z" />
    </svg>
  );
}

export default Tail;
