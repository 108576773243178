import type { ForwardedRef } from 'react';

import React, { forwardRef, useCallback, useState } from 'react';

import classy from '@core/utils/classy';
import obfuscateKey from '@core/utils/obfuscateKey';

import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import InfoPop from '@ui/InfoPop';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface Props {
  /**
   * Hide or show copy button
   */
  allowCopy?: boolean;
  /**
   * Hide or show tooltip and interaction that displays a full API key
   */
  allowExpansion?: boolean;
  /**
   * The API key to render
   */
  apiKey: number | string;
  className?: string;
  /**
   * Show either the first or last 5 characters
   */
  conceal?: 'after' | 'before';
  /**
   * Number of characters to selectively display by default
   */
  displayLength?: number;
  /**
   * Show the rotate key warning
   */
  shouldBeRotated?: boolean;
}

function ObfuscatedAPIKey(
  {
    allowCopy = true,
    allowExpansion = true,
    apiKey,
    className = '',
    conceal = 'after',
    displayLength = 5,
    shouldBeRotated = false,
  }: Props,
  ref: ForwardedRef<HTMLElement>,
) {
  const [isObfuscated, setIsObfuscated] = useState(true);
  const text = `${apiKey}`;

  const obfuscatedText = obfuscateKey(text, conceal, displayLength);

  const handleToggle = useCallback(() => {
    setIsObfuscated(!isObfuscated);
  }, [isObfuscated]);

  return (
    <Flex
      ref={ref}
      className={classy(
        classes.ObfuscatedAPIKey,
        !!allowExpansion && !isObfuscated ? 'ObfuscatedAPIKey_expanded' : '',
        className,
      )}
      gap="sm"
      justify="start"
    >
      <div className={classes['ObfuscatedAPIKey-text']}>{isObfuscated ? obfuscatedText : text}</div>

      {!!allowExpansion && (
        <Tooltip content={isObfuscated ? 'Show API Key' : 'Hide API Key'} hideOnClick={false} placement="bottom">
          <button aria-label="Toggle API Key" className={classes['ObfuscatedAPIKey-button']} onClick={handleToggle}>
            {isObfuscated ? <i className="icon-eye-2" /> : <i className="icon-eye-off" />}
          </button>
        </Tooltip>
      )}

      {!!allowCopy && <CopyToClipboard className={classes['ObfuscatedAPIKey-clipboard']} text={text} />}

      {!!shouldBeRotated && (
        <InfoPop className={classes['ObfuscatedAPIKey-rotate']} icon="icon-alert-triangle">
          We recommend you rotate this token.
        </InfoPop>
      )}
    </Flex>
  );
}

export default forwardRef(ObfuscatedAPIKey);
