/**
 * Will shorten a url if valid
 */
function shortUrl(label: string, includeQueryParams = true) {
  try {
    const url = new URL(label);
    return `${url.pathname}${includeQueryParams ? url.search : ''}`;
  } catch (e) {
    return label;
  }
}

export default shortUrl;
