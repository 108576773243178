import type { HTTPMethod } from '@readme/iso';

import React, { useCallback, useMemo } from 'react';

import { Column } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import type { MetricsFilterType } from '@core/types/metrics';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import UserAgentPlatformGraphic from '@ui/Metrics/UserAgentPlatformGraphic';
import Toggle from '@ui/Toggle';

import FilterContentWithTooltip from '../FilterContentWithTooltip';
import styles from '../style.module.scss';

interface Props {
  /** Filter value returned from API */
  filter: {
    count: number;
    name: string;
    platform?: string;
  };
  /** The filter type (based on Column enum) */
  filterType: MetricsFilterType;
  /** Whether or not filter (toggle) is checked  */
  isChecked: boolean;
  /** Set filter callback */
  setFilter: (key: MetricsFilterType, value: string) => void;
  /** Whether filter count should be shown - if false, will show filter as greyed out */
  showCount: boolean;
  /** Unset filter callback */
  unsetFilter: (key: MetricsFilterType, value: string) => void;
}

const Filter = ({ filter, filterType, isChecked, setFilter, showCount, unsetFilter }: Props) => {
  const bem = useClassy(styles, 'Filter');

  const { name, platform, count } = filter;

  const onChange = useCallback(() => {
    if (isChecked) {
      unsetFilter(filterType, name);
    } else {
      setFilter(filterType, name);
    }
  }, [isChecked, unsetFilter, setFilter, filterType, name]);

  const content = useMemo(() => {
    switch (filterType) {
      case Column.Status:
        return <HTTPStatus includeMessage={true} status={name} />;
      case Column.Method:
        return (
          <>
            <APIMethod className={bem('-APIMethod')} fixedWidth type={name as HTTPMethod} />
            <span>{name}</span>
          </>
        );
      case Column.UserAgent:
        return (
          <Flex align="center" gap="sm" justify="start">
            <UserAgentPlatformGraphic platform={platform} />
            <FilterContentWithTooltip name={name} />
          </Flex>
        );
      default:
        return <FilterContentWithTooltip name={name} />;
    }
  }, [name, filterType, platform, bem]);

  return (
    <Flex
      align="center"
      className={bem('-listItem', !showCount && '-listItem_greyed')}
      gap="sm"
      justify="start"
      tag="label"
    >
      <Toggle
        checked={isChecked}
        className={bem('-listItemToggle')}
        id={name}
        name={name}
        onChange={onChange}
        size="sm"
        value={name}
      />

      <div className={bem('-name')}>{content}</div>

      {!!showCount && count > 0 && <div className={bem('-count')}>{count?.toLocaleString()}</div>}
    </Flex>
  );
};

export default React.memo(Filter);
