import React from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

interface Props {
  isCondensed?: boolean;
  isStrong?: boolean;
  label: React.ReactNode;
  value?: React.ReactNode;
}

const TooltipItem = ({ isCondensed = false, isStrong = false, label, value, ...props }: Props) => {
  const bem = useClassy(classes, 'GraphTooltipItem');

  return (
    <div className={bem('&', isCondensed && '_condensed', isStrong && '_strong', 'rm-TooltipItem')} {...props}>
      <div className={bem('-label', 'rm-TooltipItem-label')}>{label}</div>
      {!!value && <div className={bem('-value', 'rm-TooltipItem-value')}>{value}</div>}
    </div>
  );
};

export default TooltipItem;
