import tinycolor from 'tinycolor2';

import altColors from '@core/styles/colorscale-alt.module.scss';
import grayColors from '@core/styles/colorscale-grays.module.scss';
import coreColors from '@core/styles/colorscale.module.scss';
import getCSSVar from '@core/utils/getCSSVar';

import statusCategories from './statusCategories';

const hexPalette = {
  blue: coreColors.blue50,
  green: coreColors.green50,
  purple: coreColors.purple50,
  yellow: coreColors.yellow50,
  red: coreColors.red50,
  ...altColors,
};

const hexToRGB = hex => {
  const { r, g, b } = tinycolor(hex).toPercentageRgb();
  return `${r}, ${g}, ${b}`;
};

const colors = Object.values(hexPalette).map(c => hexToRGB(c));

const blue = colors[0];
const red = colors[4];
const green = colors[1];

const statusCategoryColors = {
  '100s': 'gray',
  '200s': 'green',
  '300s': 'yellow',
  '400s': 'red',
  '500s': 'red',
};

/** Our color scales have a primary (least light or dark) tint in the middle.
 * We probably want the first error codes in the category to start the most primary,
 * so hard-code a new ordering starting with primary and trailing with contrasty tints.
 */
const orderedTints = ['50', '60', '70', '40', '30', '20', '10', '0'];
/** An alternate ordering of tints is used if there are two groups using the same color (eg. 400s & 500s) */
const orderedTintsAlternate = ['20', '10', '30', '0', '40', '50', '60', '70'];

const lookupColor = (index, theme, groupName, isComparison) => {
  switch (theme) {
    case 'empty': {
      /** Grabbing the current skeleton background color
       * because it can change between light and dark mode.
       * Falls back 50% gray.
       */
      const skeletonBG = getCSSVar('--color-skeleton') || grayColors.gray50;
      return hexToRGB(skeletonBG);
    }
    case 'page-quality': {
      /** Return red for index of 0
       * and green for index of 1
       */
      const isRed = index === 0;
      return isRed ? red : green;
    }
    case 'status-code-groups': {
      /** Return green for index of 0
       * and red for index of 1
       */
      const isGreen = index === 0;
      return isGreen ? green : red;
    }
    case 'status-codes': {
      /** Color-coded by the status code category
       */
      if (!groupName || !groupName.length) {
        return blue;
      }
      const statusCategory = `${groupName[0]}00s`;
      const indexWithinCategory = statusCategories[statusCategory].indexOf(parseInt(groupName, 10));
      const colorName = statusCategoryColors[statusCategory];
      /** 500s and 400s both use red, so use an alternate order of tints for 500s */
      const tints = statusCategory === '500s' ? orderedTintsAlternate : orderedTints;
      const colorIndex = tints[Math.min(indexWithinCategory, 7)];
      const colorScale = colorName === 'gray' ? grayColors : coreColors;
      return hexToRGB(colorScale[`${colorName}${colorIndex}`]);
    }
    default: {
      /** Cycle through the color palette by default.
       * When we have more groups than colors in the palette,
       * cycle back to the beginning of the palette.
       */

      /** for ungrouped comparison graphs, the second index should have the same color as the first */
      const offsetIndex = isComparison && index === 1 ? 0 : index;

      const offset = Math.floor(offsetIndex / colors.length) * colors.length;
      const cycledIndex = Math.abs(offset - offsetIndex);
      return colors[cycledIndex];
    }
  }
};

const setAppearance = (type, color, isComparison = false, opts = {}) => {
  return type === 'line'
    ? {
        lineTension: 0,
        backgroundColor: 'transparent',
        borderColor: `rgba(${color}, ${isComparison ? 0.5 : 1})`,
        borderDash: isComparison ? [5, 15] : [0, 0],
        ...opts,
      }
    : {
        backgroundColor: `rgba(${color}, ${isComparison ? 0.5 : 1})`,
        hoverBackgroundColor: `rgba(${color}, ${isComparison ? 0.5 : 1})`,
        ...opts,
      };
};

const parseData = ({ providedData, type, isStacked, theme = '', sortGroupsBySum = true, appearanceOptions = {} }) => {
  const datasets = [];
  let providedDatasets;

  if (isStacked) {
    /* metrics api returns stacked datasets nested in an object,
     * so we need to flatten them and key the stacks for chartjs
     */

    providedDatasets = providedData.datasets
      .map((dataset, i) => {
        if (Array.isArray(dataset.data))
          return ['page-quality', 'status-code-groups'].includes(theme) ? { ...dataset, colorIndex: 1 } : dataset;

        const nestedDatasets = Object.entries(dataset.data).map(([key, value]) => {
          return {
            /** We can't rely on array order for color coding in the case that
             * there is only one type of vote so explicitly set the color index here
             */
            colorIndex: parseInt(key, 10),
            data: value,
          };
        });

        if (!nestedDatasets.length) return dataset;

        return nestedDatasets.map(set => {
          return {
            ...set,
            stack: i === 0 ? 'current' : 'comparison',
          };
        });
      })
      .flat();
  } else {
    providedDatasets = providedData.datasets;
  }

  const isGrouped = !Array.isArray(providedDatasets[0].data);
  if (isGrouped) {
    // dataset.data is converted to a Map to preserve key insertion order
    providedDatasets.forEach(dataset => {
      let entries = Object.entries(dataset.data);
      if (sortGroupsBySum) {
        /* the api sorts responses of groupBy=period&groupBy=otherGroup alphabetically
         * so sort grouped datasets by sum of values in each group
         */
        entries = entries.sort(([, a], [, b]) => {
          const aSum = a.reduce((i, j) => i + j, 0);
          const bSum = b.reduce((i, j) => i + j, 0);
          return bSum - aSum;
        });
      }
      dataset.dataMap = new Map(entries);
    });
  }

  const groupNames = isGrouped ? Array.from(providedDatasets[0].dataMap.keys()) : [];

  const groups = groupNames?.map((name, i) => {
    return {
      name,
      color: lookupColor(i, theme, name),
      total: providedDatasets[0].data[name].reduce((acc, num) => acc + num, 0),
    };
  });

  providedDatasets.forEach((dataset, i) => {
    const colorIndex = 'colorIndex' in dataset ? dataset.colorIndex : i;

    /* comparison will be second dataset for unstacked graphs
     * or third & fourth dataset for stacked graphs
     */
    const isComparison = !isStacked ? i === 1 : i > 1;

    if (!isGrouped) {
      const color = lookupColor(colorIndex, theme, null, isComparison);
      const isDownVote = theme === 'page-quality' && colorIndex === 0;
      const opts = isDownVote ? { minBarLength: 5, ...appearanceOptions } : appearanceOptions;

      datasets.push({
        data: dataset.data,
        stack: dataset.stack,
        isComparison,
        colorIndex,
        ...setAppearance(type, color, isComparison, opts),
      });
    } else if (type === 'line' && isGrouped && !isStacked) {
      /* grouped line charts are color-coded by group */
      Array.from(dataset.dataMap.entries()).forEach(([key, value]) => {
        const color = groups.find(g => g.name === key).color;
        datasets.push({
          group: key,
          data: value,
          isComparison,
          colorIndex,
          ...setAppearance(type, color, isComparison, appearanceOptions),
        });
      });
    } else if (type === 'bar' && isGrouped && !isStacked) {
      /* grouped, unstacked bar charts are color-coded by group but colors
       * are configured by color arrays adjacent to data in dataset shape
       */
      const displayData = Array.from(dataset.dataMap.values())
        .filter(d => Array.isArray(d))
        .map(d => d.reduce((a, b) => a + b, 0));
      const bgColors = displayData.map(
        (d, j) => `rgba(${groups.find(g => g.name === groupNames[j]).color}, ${isComparison ? 0.5 : 1})`,
      );
      datasets.push({
        backgroundColor: bgColors,
        hoverBackgroundColor: bgColors,
        data: displayData,
        isComparison,
        colorIndex,
      });
    } else if (type === 'line' && isGrouped && isStacked) {
      /* grouped & stacked line charts always use red/green color coding */
      Array.from(dataset.dataMap.entries()).forEach(([key, value]) => {
        const color = lookupColor(colorIndex, theme);

        datasets.push({
          group: key,
          data: value,
          stack: dataset.stack,
          isComparison,
          colorIndex,
          ...setAppearance(type, color, isComparison, appearanceOptions),
        });
      });
    } else if (type === 'bar' && isGrouped && isStacked) {
      /* grouped & stacked bar charts always use red/green color coding */
      const displayData = Array.from(dataset.dataMap.values())
        .filter(d => Array.isArray(d))
        .map(d => d.reduce((a, b) => a + b, 0));
      const color = lookupColor(colorIndex, theme);
      datasets.push({
        data: displayData,
        stack: dataset.stack,
        isComparison,
        colorIndex,
        ...setAppearance(type, color, isComparison, appearanceOptions),
      });
    }
  });

  // Treat nulls as empty strings for label and filtering purposes
  const parsedLabels = (providedData.labels || []).map(label => (label === null ? '' : label));

  const labels = isGrouped && type !== 'line' ? groupNames : parsedLabels;

  return {
    datasets,
    labels,
    groups,
    isGrouped,
  };
};

export default parseData;
