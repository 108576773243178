import { differenceInDays, differenceInHours, differenceInMilliseconds, isThisMinute, parseISO } from 'date-fns';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import { Column } from '@core/enums/metrics';
import capitalize from '@core/utils/capitalize';

interface ElapsedOptions {
  includeAgo: boolean;
}

/**
 * Returns a slugified version of the given text for use in URLs
 */
export function slugify(text: string) {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

/** A method to take camelCased or kebab-cased text and
 *  convert it to a human readable "Label Cased" string.
 * @used in various Metrics/Filters
 */
export const smartCaps = (str: string) =>
  startCase(str)
    .replaceAll(/\bapi\b/gi, 'API')
    .replaceAll(/\burl\b/gi, 'URL')
    .replaceAll(/\bid\b/gi, 'ID')
    .replaceAll(/\buseragent\b/gi, 'User Agent');

/** Take a kebab-cased string and convert it in to a
 *  camelCased string with proper capitalization.
 * @used by the root metrics component
 */
export const toKeyString = (str: string) => (str ? capitalize(camelCase(str)).replace('Api', 'API') : '');

/**
 * Returns UpperCamelCase formatted string (and handles words with hyphens)
 * Used when displaying request headers
 */
export const upperCamelCase = (str: string) => {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
};

const MINUTE_IN_MS = 60 * 1000;
const HOUR_IN_MS = 60 * MINUTE_IN_MS;
const DAY_IN_MS = 24 * HOUR_IN_MS;
const DAYS_IN_MINUTES = 24 * 60;

/**
 * Returns a formatted string of the elapsed time between the given timestamp
 * and now in either hours or days
 */
export const getElapsed = (start: string, options: ElapsedOptions = { includeAgo: false }) => {
  const startDate = parseISO(start);
  const endDate = new Date();
  const elapsed = differenceInMilliseconds(endDate, startDate);

  let resolution = 'day';
  let elapsedTime;

  if (isThisMinute(startDate) || elapsed < MINUTE_IN_MS) {
    resolution = 'now';
  } else if (differenceInHours(endDate, startDate) < 1) {
    resolution = 'minute';
  } else if (differenceInHours(endDate, startDate) < 24) {
    resolution = 'hour';
  }

  switch (resolution) {
    case 'now':
      return 'now';
    case 'minute':
      elapsedTime = `${Math.round(elapsed / MINUTE_IN_MS)}m`;
      break;
    case 'hour':
      elapsedTime = `${Math.round(elapsed / HOUR_IN_MS)}h`;
      break;
    case 'day':
    default:
      elapsedTime = `${Math.round(elapsed / DAY_IN_MS)}d`;
      break;
  }

  return options.includeAgo ? `${elapsedTime} ago` : elapsedTime;
};

/**
 * Returns a formated string of the minutes to readable string
 * with one decimal place for hours/days
 */
export const formatMinutesToReadableString = (value: number) => {
  if (!value || value === 0) {
    return '0 minutes';
  }

  // If less than 1 day
  if (value < DAYS_IN_MINUTES) {
    const hours = value / 60;
    const formattedHours = parseFloat(hours.toFixed(1));
    return `${formattedHours} ${formattedHours === 1 ? 'hour' : 'hours'}`;
  }

  const days = value / DAYS_IN_MINUTES;
  const formattedDays = parseFloat(days.toFixed(1));
  return `${formattedDays} ${formattedDays === 1 ? 'day' : 'days'}`;
};

/**
 * Returns whether log created at date is older than 30 days
 */
export const isLogOlderThan30Days = (logCreatedAt: string) => {
  const targetDate = new Date(logCreatedAt);
  const daysDifference = differenceInDays(new Date(), targetDate);

  return daysDifference > 30;
};

/** Map of Metrics table column to display name */
export const columnHeaders = {
  [Column.APIKey]: 'API Key',
  [Column.APIRequests]: 'API Requests',
  [Column.Company]: 'Company',
  [Column.Comment]: 'Comment',
  [Column.CreatedAt]: 'Time',
  [Column.Email]: 'Email',
  [Column.EventName]: 'Event',
  [Column.GroupId]: 'API Key',
  [Column.GroupEmail]: 'Email',
  [Column.Language]: 'Language',
  [Column.Label]: 'Label',
  [Column.LastRequest]: 'Last Request',
  [Column.Link]: 'Result Clicked',
  [Column.Method]: 'Method',
  [Column.OperationId]: 'Operation ID',
  [Column.Page]: 'Page',
  [Column.Path]: 'URL',
  [Column.RecentRequest]: 'Most Recent Request',
  [Column.SearchTerm]: 'Search',
  [Column.Status]: 'Status',
  [Column.Trend]: 'Requests This Period',
  [Column.User]: 'User',
  [Column.UserAgent]: 'User Agent',
  [Column.Version]: 'Version',
};
