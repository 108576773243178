/**
 * Take an int or number and convert it in to a
 *  localized "display" string.
 * @example "52103" -> "52,103"
 * @via https://stackoverflow.com/a/149107/1341949
 */

const prettyNumber = (num: number | string) => {
  try {
    return Number(num).toLocaleString();
  } catch (err) {
    return num;
  }
};

export default prettyNumber;
