import { matchesMimeType } from 'oas/utils';
import { useCallback, useEffect, useState } from 'react';
import xmlFormatter from 'xml-formatter';

export const cleanStringify = obj => {
  return JSON.stringify(obj, undefined, 2);
};

/**
 * A hook that takes in a string of code and a language identifier
 * and formats it as XML or JSON.
 */
const useFormatResponseLogCode = (
  code: string | null | undefined,
  providedLanguage: string | 'json' | 'text' | 'xml',
) => {
  const [formattedCode, setFormattedCode] = useState(code);
  const [language, setLanguage] = useState(providedLanguage);

  useEffect(() => {
    try {
      // `code` should never be null, but just in case it is we should default the formatted code that we supply to
      // our syntax highlighter to an empty string otherwise it'll throw exceptions.
      if (!code) {
        setFormattedCode('');
        return;
      }

      switch (language) {
        case 'xml':
          setFormattedCode(xmlFormatter(code));
          break;

        case 'json':
        default:
          // Examples can be stored in the OAS as either a string or a JSON object. If it's JSON we need to stringify it
          // because CodeMirror requires data we pass to it to be a string.
          if (typeof code === 'string') {
            try {
              setFormattedCode(cleanStringify(JSON.parse(code)));
            } catch (err) {
              setFormattedCode(code);
            }
          } else {
            setFormattedCode(cleanStringify(code));
          }
          break;
      }
    } catch (e) {
      setFormattedCode(code);
    }
  }, [code, language]);

  const setFormattedLanguage = useCallback(
    (lang: string) => {
      if (matchesMimeType.json(lang)) {
        setLanguage('json');
      } else if (matchesMimeType.xml(lang)) {
        setLanguage('xml');
      } else {
        // If not JSON or XML, fallback to plain text
        setLanguage('text');
      }
    },
    [setLanguage],
  );

  return { formattedCode, language, setFormattedLanguage };
};

export default useFormatResponseLogCode;
