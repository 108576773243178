// Enum for table preferences saved in LocalStorage
export enum TablePrefsKey {
  APIKeyInsightsRequests = 'APIKeyInsightsRequests',
  MetricsApiCalls = 'MetricsApiCalls',
  MetricsApiErrors = 'MetricsApiErrors',
  MetricsNewUsers = 'MetricsNewUsers',
  MetricsPageQuality = 'MetricsPageQuality',
  MetricsPageViews = 'MetricsPageViews',
  MetricsSearch = 'MetricsSearch',
  MetricsTopEndpoints = 'MetricsTopEndpoints',
  RealtimeMyRequests = 'RealtimeMyRequests',
  RecentRequests = 'RecentRequests',
}
