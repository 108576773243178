import type { Chart, ChartType, TooltipModel } from 'chart.js';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useGraphTooltip from '@core/hooks/useGraphTooltip';
import prettyNumber from '@core/utils/prettyNumber';

import Box from '@ui/Box';
import classes from '@ui/Metrics/Graph/style.module.scss';
import TooltipItem from '@ui/Metrics/Graph/TooltipItem';
import { NO_LOGS_LABEL } from '@ui/Sparkline';

interface Props {
  chart: Chart;
  tooltip: TooltipModel<ChartType>;
}

function SparklineTooltip({ chart, tooltip }: Props) {
  // We'll use the same styling as the MetricsGraphTooltip component
  const bem = useClassy(classes, 'GraphTooltip');

  const { isVisible, style } = useGraphTooltip(chart, tooltip);
  if (!isVisible) return null;

  const label = tooltip.title[0];

  // We'll filter out "No Logs" from appearing as a label in tooltip (since it's only used in underlying chart data)
  const filteredDatapoints = tooltip.dataPoints.filter(({ dataset }) => dataset.label !== NO_LOGS_LABEL);

  // We'll reverse the order so Successful requests label is on top
  const sortedDatapoints = filteredDatapoints.reverse();

  return (
    <Box className={bem('&')} kind="pop" style={style}>
      <TooltipItem isStrong label={<span>{label}</span>} />

      {sortedDatapoints.map(({ dataset, raw }, i) => {
        const { backgroundColor: color, label: tooltipLabel } = dataset;

        const colorBoxStyle = {
          background: color as string,
          width: '16px',
          height: '16px',
          borderRadius: 'var(--border-radius)',
        };

        return (
          <TooltipItem
            key={i}
            isCondensed
            label={
              <>
                <div style={colorBoxStyle} />
                <span>{tooltipLabel}</span>
              </>
            }
            value={prettyNumber(raw as number)}
          />
        );
      })}
    </Box>
  );
}

// Don't memo me, I am not pure!
export default SparklineTooltip;
