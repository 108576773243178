import retry from 'async-retry';
import { useEffect, useRef } from 'react';

/**
 * Retry options can be found here:
 * @see {@link https://github.com/tim-kos/node-retry#retrytimeoutsoptions}
 */
function useRetry({
  isReady,
  action,
  callback,
  options = {
    factor: 2,
    retries: 6,
    minTimeout: 50, // in milliseconds,
    randomize: true, // randomizes the timeouts by multiplying with a factor between 1 to 2
  },
}) {
  const countRef = useRef(0);

  useEffect(() => {
    countRef.current = 0; // reset counter
    let isCanceled = false;
    function doCleanup() {
      isCanceled = true;
    }

    const areTypesAcceptable =
      action instanceof Promise || (typeof action === 'function' && typeof callback === 'function');
    if (!areTypesAcceptable || !isReady) {
      return doCleanup;
    }

    async function doRetry(bail) {
      if (isCanceled) {
        bail();
      }

      const result = await action();
      const isAccepted = callback(result, countRef.current);

      if (!isCanceled && countRef.current < options.retries) {
        // Pass the result to the callback to see if we need to continue retrying
        countRef.current += 1;
        if (!isAccepted) {
          throw new Error('Retrying…');
        }
      }
    }

    retry(doRetry, options).catch(e => e);

    return doCleanup;
  }, [action, callback, isReady, options]);
}

export default useRetry;
