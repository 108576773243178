import type { ChartOptions } from 'chart.js';

import React, { useMemo } from 'react';

import { renderExternalTooltip } from '@core/utils/graphTooltip';

import LineGraph from '@ui/LineGraph';
import SparklineTooltip from '@ui/Sparkline/SparklineTooltip';

const NO_LOGS_COLOR = 'rgba(0, 0, 0, 0.1)';
const NO_LOGS_DARK_MODE_COLOR = 'rgb(56,66,72)';
const FAILURE_COLOR = 'rgba(241, 124, 129, 1)'; // rgba for red60
const SUCCESS_COLOR = 'rgba(89, 212, 164, 1)'; // rgba for green60

export const NO_LOGS_LABEL = 'No Logs';

const lineGraphOpts = ({
  minY,
  maxY,
  includeTooltip = true,
  useDarkMode,
}: {
  includeTooltip;
  maxY: number | null;
  minY: number | null;
  useDarkMode;
}) => {
  return {
    animation: false,
    scales: {
      x: {
        display: false,
        stacked: true,
        offset: true,
      },
      y: {
        display: false,
        stacked: true,
        min: minY,
        max: maxY,
      },
    },
    indexAxis: 'x',
    type: 'bar',
    maintainAspectRatio: false,
    barThickness: 'flex',
    categoryPercentage: 1.0,
    barPercentage: 0.8,
    plugins: {
      crosshair: {
        strokeStyle: '#FF6666',
        lineWidth: 1,
        showBelowData: false,
        snapToData: false,
      },
      tooltip: includeTooltip
        ? {
            axis: 'x',
            mode: 'index',
            intersect: false,
            enabled: false,
            external: renderExternalTooltip(SparklineTooltip, {
              useDarkMode,
            }),
            xAlign: 'left',
            yAlign: 'center',
          }
        : {
            enabled: false,
          },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  };
};

interface Props {
  className?: string;
  /** Pass in rgba strings to change the bar chart colors */
  colorOpts?: {
    emptyData?: string;
    failureData?: string;
    successData?: string;
  };

  /**
   * Data should be an object where 0 is 'successData' (top bar) and 1 is 'failureData' (bottom bar) or an empty object for when you want a skeleton sparkline
   */
  data: Record<string, number[]>;
  /** Optional override for Error label shown in Tooltip */
  errorTooltipLabelOverride?: string;
  /** Optional height string value (% of px) for sparkline container (defaults to 30px) */
  height?: string;
  /** Optional override for including tooltip (defaults to true) */
  includeTooltip?: boolean;
  /** Labels are required for the chart to render, not sure if they actually show up anywhere */
  labels: number[] | string[];
  /** Optional max-width string value (% or px) for sparkline container (defaults to 100px) */
  maxWidth?: string;
  /** Overwrite any chart.js options here */
  opts?: ChartOptions;
  /** Optional override for Successful label shown in Tooltip */
  successTooltipLabelOverride?: string;
  /** Whether to use dark mode */
  useDarkMode?: boolean;
}

const Sparkline = ({
  className = '',
  data,
  labels,
  opts = {},
  colorOpts,
  errorTooltipLabelOverride,
  successTooltipLabelOverride,
  includeTooltip = true,
  useDarkMode = false,
  height,
  maxWidth,
}: Props) => {
  const failureData = data[1];
  const successData = data[0];

  const hasNoData = !failureData && !successData;

  const [minY, maxY] = useMemo(() => {
    if (hasNoData) {
      return [null, null];
    }
    const aggregate = !!failureData && !!successData ? [...failureData, ...successData].flat() : null;

    if (aggregate === null) {
      return [null, null];
    }

    return [Math.min(...aggregate), Math.max(...aggregate)];
  }, [failureData, successData, hasNoData]);

  const options = useMemo(() => {
    const baseLineGraphOptions = lineGraphOpts({ minY, maxY, includeTooltip, useDarkMode });

    return {
      ...baseLineGraphOptions,
      ...opts,
    };
  }, [includeTooltip, maxY, minY, opts, useDarkMode]);

  // If there's no data, return null for empty state
  if (hasNoData) return null;

  // draw a 1px line if there is no success or failure data
  const emptyData = failureData?.map((d, i) => {
    if (minY !== null && maxY !== null && d === 0 && successData[i] === 0) {
      // chart is 25px tall, so we need to calc a value
      // that'll render at 1px tall when plotted within the y scale bounds
      return (maxY + Math.abs(minY)) / 25;
    }
    return 0;
  });

  const graphData = {
    datasets: [
      // no logs on the bottom
      {
        data: emptyData,
        backgroundColor: colorOpts?.emptyData || useDarkMode ? NO_LOGS_DARK_MODE_COLOR : NO_LOGS_COLOR,
        hoverBackgroundColor: colorOpts?.emptyData || useDarkMode ? NO_LOGS_DARK_MODE_COLOR : NO_LOGS_COLOR,
        // This label is not shown and only used in SparklineTooltip to hide the label
        label: NO_LOGS_LABEL,
      },
      // failures
      {
        data: failureData,
        backgroundColor: colorOpts?.failureData || FAILURE_COLOR,
        hoverBackgroundColor: colorOpts?.failureData || FAILURE_COLOR,
        label: errorTooltipLabelOverride ?? 'Failed',
      },
      // successes on the top
      {
        data: successData,
        backgroundColor: colorOpts?.successData || SUCCESS_COLOR,
        hoverBackgroundColor: colorOpts?.successData || SUCCESS_COLOR,
        label: successTooltipLabelOverride ?? 'Successful',
      },
    ],
    labels,
  };

  return (
    <LineGraph
      className={className}
      data={graphData}
      options={options}
      style={{ position: 'relative', height: height || '30px', maxWidth: maxWidth || '100px' }}
    />
  );
};

export default Sparkline;
