import { format, formatDistance } from 'date-fns';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import type { TooltipProps } from '@ui/Tooltip';
import Tooltip from '@ui/Tooltip';

import styles from './index.module.scss';

export interface TimestampProps {
  /**
   * Class name to tack onto the root element.
   */
  className?: string;

  /**
   * Allows overriding of the default date format (by distance) with a custom
   * one. Use this to generate and display a custom timestamp label given the
   * date provided to your function.
   * @example formatter={date => format(date, 'MM/dd, hh:mmaaa')}
   */
  formatter?: (date: Date) => string;

  /**
   * Allows rendering a singleton tooltip with the provided ID.
   */
  tooltipId?: string;

  /**
   * Timestamp in the form of a date object or string.
   */
  value: Date | string;
}

/**
 * Renders a human-readable timestamp for the provided date string or instance.
 * Includes a tooltip that reveals the date in a fully expanded format. By
 * default, the provided date is formatted relative to the current time by
 * distance, e.g. `6 months ago`. To render a different format instead, use the
 * `formatter` prop to return a differently formatted date string.
 */
function Timestamp({ className, formatter, tooltipId, value }: TimestampProps) {
  const bem = useClassy(styles, 'Timestamp');
  const safeValue = useMemo(() => new Date(value), [value]);

  const tooltipProps: TooltipProps = useMemo(
    () => ({
      content: format(safeValue, 'PPPp'),
      ...(tooltipId
        ? {
            singleton: tooltipId,
          }
        : {
            arrow: false,
            delay: [800, 200],
            offset: [0, 5],
            placement: 'bottom-start',
          }),
    }),
    [safeValue, tooltipId],
  );

  return (
    <Tooltip {...tooltipProps}>
      <span className={bem('&', className)} data-testid="root">
        {formatter ? formatter(safeValue) : formatDistance(safeValue, new Date(), { addSuffix: true })}
      </span>
    </Tooltip>
  );
}

export default Timestamp;
