import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, useRef } from 'react';

import useSentry from '@core/hooks/useSentry';

import ErrorBoundary from '@ui/ErrorBoundary';
import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
);

// Custom crosshair/vertical line plugin
Chart.register({
  id: 'crosshair',
  beforeInit(chart) {
    chart.lastMouseEvent = null;
  },
  beforeEvent(chart, args, options) {
    const { event } = args;

    // Store the latest mouse event on chart for use in afterDraw method
    chart.lastMouseEvent = event.type === 'mousemove' ? event : null;

    // Redraw the chart to clear the vertical line on mouseouts
    if (event.type === 'mouseout') {
      chart.draw();
    }

    // Update vertical line on every mousemove event if snapToData is false
    if (!options.snapToData && event.type === 'mousemove') {
      chart.render();
    }
  },
  afterDraw(chart, args, options) {
    if (chart.lastMouseEvent) {
      const nearestPoint = chart.getElementsAtEventForMode(
        chart.lastMouseEvent,
        'index',
        {
          intersect: false,
        },
        true,
      )[0];

      // Grab either nearest data point or the x position of the mouse event
      const x = options.snapToData && nearestPoint ? nearestPoint.element.x : chart.lastMouseEvent.x;

      const yAxis = chart.scales.y;
      const { ctx } = chart;
      const { lineWidth, strokeStyle, showBelowData } = options;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = strokeStyle;
      ctx.globalCompositeOperation = showBelowData ? 'destination-over' : '';
      ctx.stroke();
      ctx.restore();
    }
  },
  defaults: {
    strokeStyle: 'rgba(99, 114, 136, 0.25)',
    lineWidth: 3,
    // Whether the vertical line should be shown below the data points (or above)
    showBelowData: true,
    // Whether the vertical line should snap to the nearest data point or be shown on every mousemove event
    snapToData: true,
  },
});

const ChartErrorFallback = () => (
  <Flex align="center" gap="xs" justify="center" layout="col" style={{ minHeight: '300px' }}>
    <Graphic name="warning" size="lg" />
    <p style={{ textAlign: 'center' }}>
      <strong>Something went wrong loading chart</strong> <br />
      Please refresh the page and try again
    </p>
  </Flex>
);

function LineGraph({ className = '', data, options = {}, style, useDarkMode }) {
  const [ctx, setCtx] = useState(null);
  const [chart, setChart] = useState(null);

  const canvasRef = useRef(null);
  const onError = useSentry('LineGraph');

  useEffect(() => {
    if (canvasRef.current) {
      setCtx(canvasRef.current.getContext('2d'));
    }
  }, []);

  const logError = useCallback(
    (error, info) => {
      // Log error to Sentry
      onError(error, info);
    },
    [onError],
  );

  useEffect(() => {
    if (ctx === null) return () => null;
    if (chart) chart.destroy();

    // defaults
    Chart.defaults.borderColor = useDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,.05)';
    Chart.defaults.font.family =
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
    Chart.defaults.font.size = 11;
    Chart.defaults.elements.point.radius = 0;
    Chart.defaults.elements.line.borderCapStyle = 'round';
    Chart.defaults.elements.line.borderJoinStyle = 'round';
    Chart.defaults.elements.line.borderWidth = 4;
    Chart.defaults.elements.point.pointHoverRadius = 5;
    Chart.defaults.elements.point.pointHoverBackgroundColor = useDarkMode ? '#303b42' : '#fff'; // gray15
    Chart.defaults.elements.point.hoverBorderWidth = 4;

    const newChart = new Chart(ctx, {
      type: options.type || 'line',
      data,
      options,
    });
    setChart(newChart);

    return () => chart && chart.destroy();
  }, [ctx, useDarkMode]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the chart when the data or options change
  useEffect(() => {
    if (chart) {
      chart.data = data;
      chart.options = options;
      chart.update();
    }
  }, [data, options]); // eslint-disable-line react-hooks/exhaustive-deps

  // The update method won't re-render the chart when switching from 'bar' to 'line':
  // So this effect destroys the chart and reinstantiates it
  useEffect(() => {
    if (chart) {
      chart.destroy();
      const newChart = new Chart(ctx, {
        type: options.type || 'line',
        data,
        options,
      });
      setChart(newChart);
    }
  }, [options.type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorBoundary fallback={<ChartErrorFallback />} onError={logError}>
      <div className={className} style={style}>
        <canvas ref={canvasRef} aria-label="Line Graph" />
      </div>
    </ErrorBoundary>
  );
}

LineGraph.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  style: PropTypes.object,
  useDarkMode: PropTypes.bool,
};

export default React.memo(LineGraph);
