import { createContext, useState, useCallback } from 'react';

interface AccordionContextInterface {
  activePanelIds: string[];
  closeIcon?: React.ReactNode | null;
  handleToggle: (id: string) => void;
  headingLevel?: number | string;
  openIcon?: React.ReactNode | null;
}

export const AccordionContext = createContext<AccordionContextInterface>({
  activePanelIds: [],
  handleToggle: () => {},
});

export function useAccordionContext(
  collapsible: boolean,
  headingLevel: number | string,
  openIcon: React.ReactNode | null,
  closeIcon: React.ReactNode | null,
) {
  const [activePanelIds, setActivePanelIds] = useState<string[]>([]);

  const handleToggle = useCallback(
    (id: string) => {
      if (activePanelIds.includes(id)) {
        // Close the selected panel.
        // If the accordion is not collapsible and the selected panel is the only active panel, keep it open.
        setActivePanelIds(ids => {
          return !collapsible && activePanelIds.length === 0 ? ids : ids.filter((panelId: string) => id !== panelId);
        });
      } else {
        // Open the selected panel.
        // If the accordion is not collapsible the previously active panel will be closed.
        setActivePanelIds(ids => (collapsible ? [...ids, id] : [id]));
      }
    },
    [activePanelIds, collapsible],
  );

  return { activePanelIds, headingLevel, handleToggle, openIcon, closeIcon };
}
