import React, { useContext } from 'react';

import useClassy from '@core/hooks/useClassy';

import { AccordionContext } from './context';
import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  itemId: string;
}

function AccordionContent({ children, className, itemId }: Props) {
  const bem = useClassy(classes, 'AccordionContent');
  const { activePanelIds } = useContext(AccordionContext);
  const isActive = activePanelIds.includes(itemId);

  return (
    <div
      aria-labelledby={`${itemId}-toggle`}
      className={bem('&', className)}
      hidden={!isActive}
      id={itemId}
      role="region"
    >
      {children}
    </div>
  );
}

export default AccordionContent;
